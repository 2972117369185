.videopageSetupMain {
    background: rgb(119, 215, 202);
    background: linear-gradient(
      180deg,
      rgba(119, 215, 202, 0.9167017148656338) 0%,
      rgba(26, 149, 106, 1) 100%
    );
    display: block;
    line-height: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: #666;
  }
  .videopage_div_inner span {
    color: #144351;
    font-size: 24px;
    font-weight: bold;
  }
  .videopage_div_inner {
    max-width: 1000px;
    margin: 0 auto;
    padding: 50px 15px 50px 0;
  }
  
  .videopage_div_inner h1 {
    color: #fff;
    font-size: 55px;
    font-weight: 550;
    padding: 7px 0;
  }
  .videopage_div_inner p {
    color: #fff;
    font-size: 16px;
    width: 80%;
    line-height: 25px;
    font-family: "Helvetica Neue", Helvetica, Arial;
  }
  .videopage_stepsNav {
    display: flex;
    flex-wrap: wrap;
    font-family: "Open Sans", Helvetica, sans-serif;
  }
  ul,
  ol,
  p {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
  .videopage_stepsNav li {
    margin-right: 1%;
    list-style: none;
  }
  ol {
    margin-block-start: 0.5px;
    margin-block-end: 1px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  /* .videopage_stepsNav a {
    background: #40c0a9;
    border: 1px solid #ffffff;
    border-radius: 5px;
    display: inline-block;
    font-size: 16px;
    margin-top: 15px;
    padding: 6px 12px;
    text-decoration: none;
    cursor: pointer;
  } */
  .videopage_stepsNavMainItems {
    background: #40c0a9;
    border: 1px solid #ffffff;
    border-radius: 5px;
    display: inline-block;
    font-size: 16px;
    margin-top: 15px;
    padding: 6px 12px;
    text-decoration: none;
    cursor: pointer;
    color: #ffffff;
    width: 100px;
    text-align: center;
    padding: 10px 15px;
    font-weight: 500px;
  }
  .videopage_stepsNavItems {
    background: #40c0a9;
    border: 1px solid #ffffff;
    border-radius: 20px;
    display: inline-block;
    font-size: 16px;
    margin-top: 20px;
    padding: 6px 12px;
    text-decoration: none;
    cursor: pointer;
    color: #ffffff;
  }
  /* .videopage_stepsNavMainItems:hover {
    background: #3e8b7d;
  }
  .videopage_stepsNavItems:hover {
    background: #3e8b7d;
  } */
  .videopage_stepsNav i {
    color: #ffffff;
    font-style: normal;
    opacity: 0.9;
    padding: 0 12px;
  }
  .videopageDescription {
    color: #222222;
    font-size: 18px;
    font-weight: normal;
    line-height: 30px;
    max-width: 1300px;
    margin: 40px auto;
  }
  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding: 0px;
  }
  h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 0.6em;
    margin-block-end: 0.6em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  
  .h2-5{
    font-size: 35px !important;
  }
  
  .h3-5{
    font-size: 26px !important;
  }
  
  .videopageDescription h3 {
    color: #222222;
    font-family: "Helvetica Neue";
    font-size: 30px;
    line-height: 38px;
    margin-top: 50px;
  }
  .videopageDescription h2 {
    color: #59cbb7;
    font-family: "Helvetica Neue";
    font-size: 40px;
    line-height: 48px;
    margin-top: 50px;
  }
  
  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  
  .videopageVideo_player {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 15px 1px rgba(110, 109, 109, 0.35);
    margin: 30px auto;
    max-width: 800px;
    padding: 3px;
    position: relative;
  }
  
  .videopageVideo_player_youtube{
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 15px 1px rgba(110, 109, 109, 0.35);
    margin: 30px auto;
    width: 54vw;
    height: 31vw;
    padding: 3px;
    position: relative;
  }
  
  .videopageDescription ul {
    list-style-type: disc;
  }
  
  .videopageDescription ul,
  .videopageDescription ol {
    color: #222222;
    font-size: 21px;
    font-weight: normal;
    line-height: 32px;
    padding: 0 0 0px 25px;
  }
  
  .videopageDescription li {
    padding-bottom: 15px;
  }
  
  .lastli{
    padding-bottom: 5px !important;
  }
  
  .videopageDescription h4 {
    font-family: "Helvetica Neue";
    line-height: 38px;
    font-weight: 700;
    margin-top: 50px;
  }
  
  .videopageRoomImage {
    width: 100%;
    height: 100% !important;
    background: rgb(11, 207, 168);
    border-radius: 5px;
    box-shadow: 0px 0px 15px 1px rgba(110, 109, 109, 0.35);
    margin-bottom: 30px;
    max-width: 300px;
    padding: 3px;
    position: relative;
  }
  /* .slider {
  position: relative;
  height: 90vh;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  }

  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateX(-50%);
    transition: all .5s easeß;
  } */
.current {
    opacity: 1;
    transform: translateX(0);
}

  .videopageUSBImage {
    width: 40%;
    height: 100% !important;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0px 0px 15px 1px rgba(110, 109, 109, 0.35);
    margin-bottom: 30px;
    max-width: 800px;
    padding: 0px;
    position: relative;
  }
  
  .videopageACXImage {
    width: 75%;
    height: 100% !important;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 15px 1px rgba(110, 109, 109, 0.35);
    margin-bottom: 30px;
    max-width: 800px;
    padding: 8px;
    position: relative;
  }
  
  .videopage_rightchevron{
    height: 15px;
    margin-left: 10px;
  }
  
  .videopageRoomImageMain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
  }
  
  .videopageBrowseImgDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 3%;
    padding-bottom: 6%;
    width: 96%;
  }
  
  .videopageBrowserImgChrome {
    width: 7%;
    margin-right: 2.5%;
    height: 100% !important;
  }
  
  .videopageSampleRecordMain {
    background-color: rgb(236, 241, 243);
    padding: 3%;
    border-radius: 5px;
    text-align: center;
    margin-top: 5%;
  }
  
  .videopageRecordButton {
    background-color: rgb(255, 255, 255);
    width: 20%;
    background-color: #ffffff;
    text-align: center;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    color: #59cbb7;
    padding: 0.6%;
    border-radius: 5px;
    cursor: pointer;
    border: solid;
    border-color: #59cbb7;
    border-width: 1.5px;
    margin-top: 1%;
    margin-bottom: 1%;
    list-style: none;
  } 
  
  .videopageRecordButtonMain {
    display: flex;
    justify-content: center;
  }
  
  .videopageconfetti {
    width: 50%;
    height: 100% !important;
    margin: 5%;
  }
  
  .videopagecongratz {
    color: red;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 5%;
    margin-top: -8%;
  }
  
  .videopageaboluteShortcts {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 8%;
    padding: 100px 0 0 0;
    width: 50px;
    overflow: visible;
    display: block;
  }
  
  .videopageShortcutimg {
    width: 100%;
    height: 100% !important;
    cursor: pointer;
    overflow: hidden;
  }
  
  .videopageShortcutimgDiv {
    padding: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease-out;
    opacity: 0.8;
  }
  
  /* .videopageShortcutimgDiv:hover {
    transition: transform 0.5s ease-out;
    transform: scale(1.3);
    opacity: 1;
  } */
  .videopage_acx {
    font-weight: 800;
    color: rgb(73, 170, 177);
  }
  
  .recordingNextpage {
    border: 3px solid #59cbb7;
    border-radius: 5px;
    margin-bottom: 35px;
    padding: 10px 25px;
  }
  
  .recordingSuggestions {
    border: 1px solid #d9d9de;
    border-radius: 5px;
    padding: 25px 25px 10px 25px;
    background-color: #f5f8fa;
  }
  
  .videopage_recodingMoreMain {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
  
  .videopage_browserCheckModal_MainDiv {
    padding: 2%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4%;
    
  }
  
  .videopage_modalinnerdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  .videopage_recodingMoreMainImg {
    width: 35%;
    height: 100% !important;
    border-radius: 2px;
  }
  .fulldev{
    background-color: #ffffff;
  }
  .bannerDiv{
    background-color: #d9e9f0;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 1.0rem;
    /* padding-top: 2.5rem;
    padding-bottom: 2rem; */
    /* border-radius: 5px; */
    /* height: 550px;
    width: 1600px; */
    max-height: 650px;
    /* width: 100%; */
  /* border-radius: 5%; */
    color: white;
  }
    .banner{
    /* background-image: url('https://s3.us-west-2.amazonaws.com/www.audifyz.com/Images/VideoPage+Banner+11.jpg');
    background-size: cover; */
    /* padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem; */
    height: 550px;
    color: white;
}

.banner1{
  /* background-image: url('https://s3.us-west-2.amazonaws.com/www.audifyz.com/Images/VideoPage+Banner+11.jpg');
  background-size: cover; */
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  /* padding-top: 2.5rem;
  padding-bottom: 2rem; */
  /* border-radius: 10px; */
  /* height: 550px;
  width: 1600px; */
  max-height: 450px;
  width: 100%;
border-radius: 5%;
  color: white;

}
.article {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
}

.header {
  font-size: 80px;
  color: white;
  text-align: center;
}



.content{
    padding-top: 140px;
    height: 220px;
    padding-left: 1rem;
    }

.youtubeVideos{
    padding-top: 20px;
    height: 220px;
    padding-left: 0rem;
    /* background-color: #111; */
}


.button{
color: rgb(0, 0, 0);
outline: none;
border: none;
font-weight: 700;
border-radius: 5px;
padding-left: 2rem;
padding-right: 2rem;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
background-color: rgba(51, 51, 51, 0.5);
cursor: pointer;
margin-right: 2rem;
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
/* .button:hover{
    color: black;
    background-color: #e6e6e6;
} */
.closeButton{
color: white;
outline: none;
border: none;
font-weight: 900;
border-radius: 0px;
padding-left: 96%;
padding-right: 2rem;
padding-top: 0rem;
padding-bottom: 0rem;
background-color: rgba(0, 0, 0, 1);
cursor: pointer;
margin-right: 0rem;
}
/* .closeButton:hover{
    color: rgba(255, 255, 255, 0.44);
    background-color: #000000;
} */

.overlay {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: #008CBA;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: .5s ease;
}

.title{
  position: absolute;
  
    padding-top: 4rem;
    /* font-size: 5rem; */
    font-weight: 800;
    padding-bottom: 0.2rem;
    text-align: center;
    color: #59cbb7;
     text-shadow: 2px 2px #023d03;
     margin-left: 35%;
    margin-top: 10%;

    font-size: 3.55vw;
   font-weight: 600;
   display: flex;
   flex-direction: row;
   font-family:cursive;
}
.description{
  position: absolute;
    color: #59cbb7;
    padding-top: .5rem;
    font-size: 2.5rem;
    text-align: center;
    text-shadow: 3px 2px 2px #000000;
    text-align: center;
    margin-left: 28%;
    margin-top: 18%;


font-family:artiste;
}

.sub_headding{
  font-size: 1.5rem;
  /* margin-left: 1rem; */
  color: #113420;
  white-space: nowrap;
  overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 1rem;
}
.desc{
  font-size: 1rem;
  /* margin-left: 1rem; */
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 1rem;
}
.fade_bottom{
    height: 330px;
    background-image: linear-gradient(180deg,transparent,rgba(37,37,37,.11),#111);
}
.row{
    margin-left: 20px;
    color: black;
}
.videos{
    display: flex;
    /* padding: 20px; */
    overflow-x: scroll;
    overflow-y: hidden;
    height: 170px;
}
.rowVideos{
    max-height: 250px;
    margin-right: 10px;
}
/* .rowVideos::-webkit-scrollbar{
    display: none;
} */



  .videopage_browserCheckModal_Heading {
    font-size: 25px;
    font-weight: 500;
    color: #59cbb7;
    margin-bottom: 3%;
    
  }
  
  .videopagepage_videopage {
    width: 22px;
    height: 100% !important;
    margin-right: 15px;
  }
  
  @media (max-width: 700px) {
    .videopage_div_inner {
      width: 100%;
      padding: 5% 3% 8% 3%;
      display: flex;
      flex-direction: column;
    }
    .videopage_div_inner h1{
      font-size: 13vw;
      text-align: left;
    }
    .title {
      color: #59cbb7;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 600;
      font-size: 45px;
      margin-top: 36%;
      text-align: center;
    }
    .discription {
      color: #59cbb7;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 600;
      font-size: 24px;
      margin-top: 36%;
      text-align: center;
    }
    .videopage_div_inner p{
      text-align: left;
    }
    .videopage_stepNav_container{
      width: 100%;
      display: flex;
      padding: 0%;
    }
    .videopage_stepsNav{
      width: 100%;
      display: flex;
    }
    .videopage_stepsNavMainItems{
      font-size: 4vw;
      width: 20vw;
      text-align: center;
      padding-left: 0%;
      padding-right: 0%;
      padding-top: 2vw;
      padding-bottom: 2vw;
    }
    .videopageDescription {
      padding: 5%;
    }
    .videopageaboluteShortcts {
      width: 0px;
    }
    .videopageVideo_player_youtube{
      width: 90vw;
      height: 53vw;
    }
    .videopageRoomImage {
      width: 80%;
    }
    .videopageUSBImage {
      width: 80%;
    }
    .videopageBrowserImgChrome {
      width: 15%;
    }
    .videopageACXImage {
      width: 85%;
    }
    .banner1{
      /* background-image: url('https://s3.us-west-2.amazonaws.com/www.audifyz.com/Images/VideoPage+Banner+11.jpg');
      background-size: cover; */
      padding-left: .5rem;
      padding-right: .5rem;
      margin-top: 1.7rem;
      margin-bottom: .5rem;
      /* padding-top: 2.5rem;
      padding-bottom: 2rem; */
      /* border-radius: 10px; */
      /* height: 550px;
      width: 1600px; */
      max-height: 550px;
      width: 100%;
    border-radius: 5%;
      color: white;
    
    }
   
    
  }
  .headdiv{
    display: flex;
    justify-content: space-between;
    padding-right: 1rem;
    padding-left: 1rem;
    /* margin: 1rem; */
  }

.rowdiv{
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
  margin: 1rem;
  /* overflow-x: scroll; */
}
.rowdiv1{
  /* display: flex; */
  /* justify-content: space-between; */
  padding-right: 1rem;
  margin: 1rem;
  align-items: flex-start;
  /* overflow-x: scroll; */
}
.bannerdiv2{
  display: flex;
  justify-content: space-between;
  background-color: #b2dae7;
  /* padding-right: 1rem; */
  
  margin: 0rem;
  /* margin-bottom: 2rem; */
  /* overflow-x: scroll; */
}
.bannerdiv{ 
  background-color: #ffffff; 
  margin-bottom: 1rem; 
}
.buttonStyle{
  width: 30px;
  height: 30px;
  border: 1px solid;
  display: flex;
  margin-right: 1rem;
  border-radius: 7px;
  box-shadow: #000000;
  cursor: pointer; 
}
.buttonDiv{
  display: flex;
}
  



@import url(https://fonts.googleapis.com/css?family=Roboto:300);
 .artist {
	 max-width: 440px;
	 height: 360px; 
   margin-right: 1.5rem;
    background: #282828; 
 
}
 .artist:last-child {
	 margin: 0;
}
 .artist__thumbnail {
	 position: relative;
	 width: 440px;
	 height: 240px;
	 overflow: hidden;
   /* overflow-x: scroll; */
}
 .artist__blur {
	 position: absolute;
	 top: 0;
	 left: 0;
	 /* filter: blur(40px); */
   /* border-radius: 50px; */
   max-width: 440px;
	 z-index: 1;
}
 .artist__image {
	 width: 120px;
	 height: 120px;
	 border-radius: 50%;
	 z-index: 3;
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -50%);
}
 .artist__ring {
	 width: 460px;
	 height: 160px;
	 border-radius: 50%;
	 background: linear-gradient(90deg, rgba(255, 255, 255, .2) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(255, 255, 255, .2) 100%);
	 z-index: 2;
	 box-shadow: 4px 0 2px -4px rgba(255, 255, 255, .9), -4px 0 2px -4px rgba(255, 255, 255, .9);
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -50%);
}
 .artist__ring--outer {
	 width: 200px;
	 height: 200px;
}
 .artist__label {
	 display: flex;
	 height: 120px;
	 flex-direction: column;
	 justify-content: space-between;
	 padding: 15px 10px;
}
 .capital {
	 color: #7f7f7f;
	 text-transform: uppercase;
}
 *, *:after, *:before {
	 box-sizing: border-box;
}

/* .artist1 :hover:after {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: rgb(0, 0, 0);
  content: 'Play Video';
 
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  width: 100%;
} */
/* .slick-slide:hover::after {
  opacity: 1; 
} */

.slick-slide {
  cursor:crosshair;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  
}

.slick-slide:hover img {
  transform: scale(1.3); 



}
.slick-slide img {
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
  width: 100%;
}

.slick-slide-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 10%;
  margin: 0;
  list-style: none;
  text-align: center;
}
.rowDivision{
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
  overflow-x: scroll;
  margin:2rem;
  padding-top: 5rem;

  /* scroll-behavior: smooth;
   
  /* scrollbar-color: #59cbb7; */
  /* scrollbar-base-color: #59cbb7;  */
}
 /* html, body {
	 height: 100%;
} */
 /* body {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 background:#59cbb7;
	 color: #fefefe;
	 font-family: Roboto;
	 font-size: 14px;
}
  */




  @import url(https://fonts.googleapis.com/css?family=Roboto:300);
  .artist1 {
    max-width: 440px;
    height: 360px;
    /* margin-left: 1.5rem;  */
    /* margin-right: 1.5rem; */
     background: #282828;
    /* float: left; */
    /* border-radius: 50px; */
    /* margin: 5rem; */
 
   
 }
  .artist1:last-child {
    margin: 0;
 }
  .artist1__thumbnail {
    position: relative;
    width: 440px;
    height: 240px;
    /* overflow: hidden; */

 
    /* overflow-x: scroll; */
 }
  .artist1__blur {
    position: absolute;
    top: 0;
    left: 0;
    /* filter: blur(40px); */
    /* border-radius: 50px; */
    max-width: 440px;
    z-index: 1;
    
 }
  .artist1__image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    z-index: 3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 }
  .artist1__ring {
    width: 460px;
    height: 160px;
    border-radius: 50%;
    background: linear-gradient(90deg, rgba(255, 255, 255, .2) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(255, 255, 255, .2) 100%);
    z-index: 2;
    box-shadow: 4px 0 2px -4px rgba(255, 255, 255, .9), -4px 0 2px -4px rgba(255, 255, 255, .9);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 }
  .artist1__ring--outer {
    width: 200px;
    height: 200px;
 }
  .artist1__label {
    display: flex;
    height: 120px;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 10px;
 }
  .capital {
    color: #7f7f7f;
    text-transform: uppercase;
 }
  *, *:after, *:before {
    box-sizing: border-box;
 }
 
  

.rowDivision1{
  /* display: flex; */
  justify-content: space-between;
  padding-right: 1rem;
  /* overflow-x: scroll; */
  /* float: left; */
  margin-left:5rem;
  margin-right: 5rem;

}
/* 
 
.slick-initialized .slick-slide{
  background-color: #b32532;
  color: #FFF;
  height: 200px;
  margin: 0 15px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}



.slick-next, .slick-prev{
  z-index: 5;
}
.slick-next{
  right: 15px;
}
.slick-prev{
  left: 15px;
}
.slick-next:before, .slick-prev:before{
  color: #000;
  font-size: 26px;
} */



.box1{
  max-width: 275px;
  height: 210px;

  /* margin-left: 1.5rem;  */
  /* margin-right: 1.5rem; */
   background: #2e6181;
   margin: 1rem;
   
   /* vertical-align: bottom; */
  /* float: left; */
  border-bottom-left-radius: 50px;
  

 
}

.slide-arrow {
  position: absolute;
  top: 50%;
  margin-top: -15px;
}
.prev-arrow {
  left: -40px;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 15px solid #113463;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.next-arrow {
  right: -40px;
  width: 0;
  height: 0;
  border-right: 0 solid transparent;
  border-left: 15px solid #113463;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.box1__thumbnail {
  
  position: relative;
  vertical-align: bottom;
  /* max-width: 295px;
  height: 160px; */
  max-width: 100%;
    height: 67%;
  overflow: hidden;
  /* overflow-x: scroll; */
  backface-visibility: hidden;
   object-fit: fill;
}

.box1__img {
  position: absolute;
  /* top: 1rem;
  left: 1rem; */
  /* padding: 1rem; */
  /* margin: 1rem; */
  /* filter: blur(40px); */
  /* border-radius: 50px; */
  /* max-width: 440px; */
  backface-visibility: hidden;
  z-index: 1;
  object-fit: cover;
  max-width: 100%;
        max-height: 100%;
      
        /* display: block; */
}

/* .slick-box1__img:hover img {
  transform: scale(1.3); 

} */

@media (max-width: 700px) {
  .box1{
    max-width: 260px;
    height: 195px; 
     background: #3e6d70;
     margin: 1rem; 
 
     margin-left: 2rem;
  }

  .box1__thumbnail {

    position: relative;
    width: 260px;
    height: 140px;
    overflow: hidden;
    /* overflow-x: scroll; */
  }
  .box1__img {
    position: absolute;
    /* top: 1rem;
    left: 1rem; */
    /* padding: 1rem; */
    /* margin: 1rem; */
    /* filter: blur(40px); */
    /* border-radius: 50px; */
    max-width: 260px;
    z-index: 1;
  }
  
  .sub_headding{
    font-size: 1rem;
    margin-top: 1rem;
    color: #113420;
  }
  .buttonDiv{
    display: flex;
    opacity: 0;
  }
  .bannerdiv{ 
    margin-bottom: 0rem; 
  }
  /* .slick-box1__img:hover img {
    transform: scale(1.3); 
  
  } */
}
/* .slick-prev:before {
  content: url('your-arrow.png');
}
.slick-next:before {
  content: url('your-arrow.png');
} */
.overlay{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  overflow: hidden;
  width:100%;
  height: 0;
  transition: 0.5s ease;


}
 .artist1__blur:hover .overlay{
  height: 100%;
}


.samplemiddle_playing{
  transition: .2s ease;
  opacity: 1;
  position: absolute;
  /* width: 50%; */
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  z-index: 5;

}

.playbutton{
  max-width: 50%;
  /* width: 10%; */
  object-fit: contain;
  opacity: 0;
  /* top: 50%;
  left: 60%; */
  /* position: absolute; */
  /* z-index: 1; */
}

.bannerdiv2 :hover .playbutton {
  opacity: 1;


}