.customizationpage_Main {
  overflow: hidden;
  width: 100%;
  height: 93%;
  position: absolute;
  background: rgb(217, 244, 238);
  background: linear-gradient(
    180deg,
    rgba(217, 244, 238, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.customizationpage_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 8%;
  background: rgba(5, 5, 5, 0.5);
  transition: all 0.4s ease-in-out;
  opacity: 0.7;
}

.customizationpage_footer:hover {
  transform: translateY(0);
  opacity: 1;
}

.customizationpage_Heading {
  font-size: 35px;
  color: #29817b;
  font-weight: 600;
  padding-top: 0.2%;
}
.customizationpage_Heading_Div_Group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.customizationpage_Heading_Group {
  font-size: 35px;
  color: #29817b;
  font-weight: 600;
  padding-top: 0.2%;
}
.customizationpage_pagenation {
  padding-left: 2%;
  display: flex;
  align-items: center;
  width: 43%;
  border-color: #05272c;
  font-size: 17px;
}
.customizationpage_pagenationInput {
  margin-top: 1%;
  height: 25px;
  margin-left: 0%;
  font-size: 15px;
  color: #464949;
  padding-left: 1%;
}
.customizationpage_pageandparaDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 87%;
  position: absolute;
  padding: 20px 0px;
}
.customizationpage_PageMainDiv {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding-top: 1%;
  padding-bottom: 6%;
  border-color: rgb(151, 151, 151);
  border-width: 0.5px 1px 0.5px 0.5px;
  /* scroll-snap-type: y mandatory; */
  height: 102%;
}

.customizationpage_PageMainDiv_scrolldisabled {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  overflow-y: hidden;
  padding-top: 1%;
  padding-bottom: 6%;
  border-color: rgb(151, 151, 151);
  border-width: 0.5px 1px 0.5px 0.5px;
  /* scroll-snap-type: y mandatory; */
  height: 100%;
}

.customizationpage_PageMainDiv::-webkit-scrollbar {
  display: none;
}
.customizationpage_ParaMainDiv {
  width: 55%;
  height: 102%;
  padding-bottom: 10px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  border-width: 0.5px 1px 0.5px 0.5px;
}
.customizationpage_ParaMainDiv::-webkit-scrollbar {
  display: none;
}

.customizationpage_ParaMainDivDisable {
  width: 60%;
  height: 94%;
  background-color: rgb(136, 35, 35);
  padding-bottom: 10px;
  overflow-y: scroll;
  border-style: solid;
  border-color: rgb(151, 151, 151);
  background-color: rgb(235, 242, 243);
  border-width: 0.5px 1px 0.5px 0.5px;
}
.customizationpage_ParaMainDivDisable::-webkit-scrollbar {
  display: none;
}
.customizationpage_iamgeMainDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
}
.customizationpage_imageStyle {
  width: 60%;
  min-height: 53vh !important;
  margin-top: 0.5%;
  border-radius: 10px;
  border: 1px solid transparent;
  box-shadow: 0px 0px 3px 0px lightgrey;
  scroll-snap-align: center;
  cursor: pointer;
  background-color: white;
}
.customizationpage_imageStyleSelected {
  width: 60%;
  min-height: 53vh !important;
  margin-top: 0.5%;
  /* margin-bottom: 5%; */
  border-radius: 10px;
  border: 3px solid #59cbb7;
  box-shadow: 1px 2px 5px 0px #59cbb7;
  scroll-snap-align: center;
  cursor: pointer;
  background-color: white;
  /* height: 10%; */
}

.customizationpage_pagebottomDiv {
  display: flex;
  justify-content: flex-end;
  width: 59%;
  padding: 0% 4%;
  margin-top: -5%;
  margin-bottom: 10%;
  margin-left: 20%;
}

.customization_pagemagnify {
  display: flex;
  width: 10%;
  overflow: hidden;
  background-color: #69e9d1;
  font-size: 0%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 1%;
  /* box-shadow: 1px 2px 5px 0px #59CBB7; */
  cursor: pointer;
  margin-left: 5%;
}

.customization_pagemagnify:hover {
  /* font-size: 80%; */
  transition: transform 0.3s ease-out;
  transform: scale(1.5);
  /* padding: 0% 2%; */
  /* box-shadow: 1px 2px 5px 0px #59CBB7; */
}

.customization_magnifinggalssmainStyle {
  background-color: rgba(5, 5, 5, 0.4);
  color: #ffffff;
  border-radius: 0px 0px 0px 7px;
  width: 50%;
  font-size: 80%;
  margin-bottom: 4%;
  cursor: pointer;
  transition: transform 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customizationpage_deletePageDiv {
  background-color: rgba(5, 5, 5, 0.4);
  color: #ffffff;
  border-radius: 0px 0px 7px 0px;
  width: 50%;
  font-size: 80%;
  margin-bottom: 4%;
  cursor: pointer;
  transition: transform 0.3s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customizationpage_paraOutlineDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 2% 0% 0%;
}
.customizationpage_paraOutlineDivDisable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 2% 0% 0%;
  pointer-events: none;
}
.customizationpage_ParagraphDiv {
  background-color: rgb(253, 253, 253);
  padding: 2% 2% 4% 2%;
  border: 1px solid transparent;
  border-radius: 5px;
  line-height: 24px;
  height: auto;
  /* width: 725px; */
  max-height: 500px;
  min-height: 135px;
  box-shadow: 0px 0px 5px 0px #bcc0c0;
  overflow-y: scroll;
}

.customizationpage_ParagraphDiv::-webkit-scrollbar {
  display: none;
}
.customizationpage_confirm {
  margin: 3% 0% 2% 0%;
  padding: 1% 1% 1% 1%;
  background-color: #38807b;
  color: #fff;
  font-size: 19px;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  width: 65%;
  transition: transform 0.3s ease-out;
}
.customizationpage_confirm:hover {
  text-decoration: none;
  color: #fff;
  transform: scale(1.01);
}
.customizationpage_Disableconfirm {
  margin: 3% 0% 2% 0%;
  padding: 1% 1% 1% 1%;
  background-color: #97c2be;
  color: rgb(121, 121, 121);
  font-size: 19px;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  width: 65%;
}
.customizationpage_indexTextDeleteSameDiv {
  display: flex;
  flex-direction: row;
}
.customizationpage_pageindex {
  margin: 3% 0% 0% 0%;
  margin-top: 0.4%;
  height: 1%;
  border-radius: 4px 0px 0px 4px;
  background-color: #59cbb7;
  color: #fff;
  padding: 10px;
  opacity: 0.5;
  font-size: 60%;
  font-weight: bold;
}

.customizationpage_pageindex:hover {
  transition: 0.3s ease-out;
  opacity: 1;
  font-size: 80%;
  background-color: #38807b;
}

.customizationpage_deleteimage {
  height: 32px;
  width: 32px;
  cursor: pointer;
}
.customizationpage_parafooter {
  height: 2%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -3.5%;
  padding-right: 2%;
  position: relative;
}

.customization_parafooterIcons {
  display: flex;
  width: 15%;
  justify-content: space-around;
}
.customizationpage_IconsDiv {
  border: solid;
  border-width: 1px;
  border-color: #59cbb7;
  border-radius: 25px;
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0px 0px 4px 1px #59cbb7;
  transition: transform 0.5s ease-out;
}
.customizationpage_IconsDiv:hover {
  transition: transform 0.5s ease-out;
  transform: scale(1.6);
  box-shadow: 0px 0px 5px 2px #59cbb7;
}
.customizationpage_addpara {
  height: 24px;
  width: 24px;
  cursor: pointer;
  opacity: 1;
  padding: 3px;
  background: white;
}

.customizationpage_addpara:hover {
  /* box-shadow: 0px 0px 1px 1px lightgrey; */
  opacity: 1;
}

.customizationpage_Mergebutton {
  height: 27px;
  width: 27px;
  cursor: pointer;
  margin-top: 20%;
  margin-bottom: 15px;
}
.customizationpage_Addbutton {
  height: 27px;
  width: 27px;
  cursor: pointer;
  margin-bottom: 15px;
}
.customizationpage_ParagraphAddingDiv {
  background-color: rgb(253, 253, 253);
  padding: 2%;
  border-radius: 5px;
  width: 720px;
  min-height: 150px;
  height: auto;
  box-shadow: 0px 0px 5px 0px #bcc0c0;
  margin: 3% 0% 0% 1.3%;
}
.customizationpage_ParaDletebutton {
  height: 29px;
  width: 29px;
  cursor: pointer;
  margin-left: 60%;
  /* margin-bottom: 40%; */
}
.customizationpage_addparaImagesMainDiv {
  display: flex;
  flex-direction: column;
}
.customizationpage_ParaUploadbutton {
  height: 29px;
  width: 29px;
  cursor: pointer;
  margin-left: 60%;
  margin-top: 100%;
}
.SaveButton_editparagraph {
  background-color: #38807b;
  color: aliceblue;
  width: 10%;
  text-align: center;
  margin-left: 1%;
  border-radius: 5%;
  padding: 0.5%;
  cursor: pointer;
}
.customizationpage_ParagraphAddingDiv::-webkit-scrollbar {
  display: none;
}
.customizationpage_mergeModalStyle {
  padding: 5%;
  text-align: center;
}
.customizationpage_mergeModalsameLine {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.customizationpageAboveOrbelow {
  background-color: #38807b;
  padding: 1%;
  margin: 0% 1% 0% 2%;
  border-radius: 3px;
  width: 10%;
  color: rgb(236, 240, 243);
  font-size: 18px;
  cursor: pointer;
}
.customizationpage_mergeModalmainheading {
  font-size: 23px;
  padding: 3% 0% 3% 0%;
}
.react-switch {
  vertical-align: middle;
  margin: 20px 10px 0px -70px;
}
.customizationpage_conformation_text {
  font-size: 19px;
  text-align: center;
  padding: 10% 0% 0% 0%;
}
.customization_pageNumber {
  width: 4.4%;
  min-height: 33px !important;
  padding: 1% 2% 1% 1%;
  margin: 15px 0px 0px 0px;
  background-color: #59cbb7;
  height: 8%;
  border-radius: 20px 0px 0px 20px;
  color: #ffffff;
  box-shadow: 0px 0px 5px 0px #59cbb7;
  font-size: 17px;
  opacity: 0.3;
}

.customization_pageNumber:hover {
  opacity: 1;
  transition: 0.3s ease-out;
}
.customizationpage_conformationDiv {
  height: 500px;
  width: 700px;
  background-color: rgb(136, 73, 73);
}
.customization_modalQuote {
  font-size: 19px;
  padding-top: 2%;
}
.magingingglassStyle {
  margin-top: 0%;
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin: 5%;
}

.customizeleftsideSelectedImageMain {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.customizeleftsideSelectedImage {
  width: 100%;
}
.customizeleftsideSelectedImageClose {
  background-color: #1b7e85;
  height: 55px;
  padding: 20px 5px 0px 5px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 0px 0px 5px 5px;
  margin-left: -13%;
  margin-top: -0.5px;
}

/*  */
/* book Grouping css */
/*  */

.bookGrouping_Main {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 92%;
  background: rgb(217, 244, 238);
  background: linear-gradient(
    180deg,
    rgba(217, 244, 238, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 20px 0px;
}
.bookGrouping_leftDiv {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1%;
}
.bookGrouping_RightDiv {
  width: 80%;
  padding-top: 1%;
  padding-bottom: 3%;
  display: flex;
  flex-direction: column;
}

.bookgroupingrightcaption_div {
  display: flex;
  width: 70%;
  padding-left: 5%;
  align-items: flex-end;
  padding-bottom: 0.3%;
}

.bookgroupingsubheading {
  color: #59cbb7;
  font-size: 110%;
  font-weight: 500;
}
.bookGroupingaddandresetdiv {
  display: flex;
  margin-bottom: 1%;
}
.bookGroupingResetMain_disabled {
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(170, 169, 169, 0.3);
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3% 1%;
  background-color: gray;
  opacity: 0.5;
  font-weight: 550;
  color: #ffffff;
  width: 35%;
  margin-right: 10%;
}
.bookGroupingResetMain {
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(170, 169, 169, 0.3);
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3% 1%;
  background-color: rgb(255, 255, 255);
  font-weight: 550;
  color: #59cbb7;
  width: 38%;
  border: solid;
  border-width: 1px;
  border-color: #ffffff;
  overflow: hidden;
  margin-right: 10%;
}
.bookGroupingResetMain_ss:hover {
  transform: scale(1.01);
  -webkit-transition-duration: 0.4s; /*Safari */
  transition-duration: 0.4s;
  border-color: #59cbb7;
}
.bookGroupingAddImage {
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(170, 169, 169, 0.3);
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1%;
  background-color: rgb(255, 255, 255);
  font-weight: 550;
  color: #59cbb7;
  margin-left: 2%;
  width: 14%;
  border: solid;
  border-width: 1px;
  border-color: #ffffff;
}

.bookGroupingSelectImage {
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(170, 169, 169, 0.3);
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1%;
  background-color: rgb(255, 255, 255);
  font-weight: 550;
  color: #59cbb7;
  margin-left: 2%;
  width: 14%;
  border: solid;
  border-width: 1px;
  border-color: #ffffff;
}
.bookGroupingProceedtoRecord {
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(170, 169, 169, 0.3);
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1%;
  background-color: #ffffff;
  font-weight: 550;
  color: #59cbb7;
  width: 14%;
  margin-left: 3%;
}
.grouping_chapters_container {
  width: 100%;
  overflow-y: scroll;
  height: 95%;
}
.grouping_chapters_container::-webkit-scrollbar {
  display: none;
}
.bookGroupingCreationofNewChapter {
  margin: 1% 1% 5% 1%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  height: 35%;
  border-radius: 3px;
}
.bookGroupingCreationMain {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bookGroupingChaptersMainDiv {
  display: flex;
  flex-direction: row;
  padding-top: 5%;
  padding-left: 5%;
  margin: 3% 2% -10% 10%;
  height: 17%;
  background: white;
  border-radius: 10px 10px 10px 10px;
  -webkit-box-shadow: 1px -12px 11px -3px rgba(209, 205, 209, 1);
  -moz-box-shadow: 1px -12px 11px -3px rgba(209, 205, 209, 1);
  box-shadow: 1px -12px 11px -3px rgba(209, 205, 209, 1);
  cursor: pointer;
}
.bookGroupingChapterHeadingDiv {
  display: flex;
  flex-direction: column;
  width: 90%;
}
.bookGroupingPageResetImgDiv {
  height: 25%;
}
.GroupingpageEditImg {
  height: 17px;
  width: 17px;
  margin: 1% 3% 0% 0%;
}
.smallPageSize {
  width: 120px;
  height: 150px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}
.bookGroupingAllPageListing {
  /* width: 12%; */
  height: 28%;
  display: flex;
  flex-direction: row;
  margin: 2% 2%;
}
.bookGroupingAllPagesListingDiv {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  padding-left: 3%;
}
.bookGroupingAllPagesListingDiv::-webkit-scrollbar {
  display: none;
}
.bookGroupingcheckbox {
  height: 8%;
  margin-top: 6%;
  margin-left: -14%;
  cursor: pointer;
}
.bookGroupingcheckbox:hover {
  transform: scale(1.09);
}
.bookGroupingSinglepageView {
  display: flex;
  flex-direction: column;
}
.bookGroupingCreationHeading {
  display: flex;
  width: 100%;
  padding-top: 0%;
  padding-left: 10%;
  margin-bottom: 10%;
  color: #59cbb7;
  font-size: 40px;
  font-weight: 600;
}
.bookGroupingViewpage {
  font-size: 12px;
}
.bookgroupingResetSingleChapter {
  margin: 3% 2% -15% 10%;
  text-align: center;
  background-color: rgb(218, 214, 214);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  padding: 0.5%;
}
.bookGroupingcloseChapter {
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(170, 169, 169, 0.3);
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1% 3% 1% 3%;
  background-color: rgb(255, 255, 255);
  font-weight: 550;
  color: #59cbb7;
  border: solid;
  border-width: 1px;
  border-color: #ffffff;
}
.bookGroupingcloseChapter:hover {
  transform: scale(1.01);
  -webkit-transition-duration: 0.4s; /*Safari */
  transition-duration: 0.4s;
  border-color: #59cbb7;
}
.bookGroupingViewpageMainDiv {
  display: flex;
  flex-direction: row;
  background-color: rgb(218, 214, 214);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.groupingpageMagnifingGlass {
  width: 13px;
  height: 10px;
  padding-right: 2%;
  padding-top: 1%;
}
.bookGroupingPageNumber {
  background-color: #59cbb7;
  height: 15%;
  min-width: 15px;
  margin-top: 10%;
  padding: 2% 0% 3% 2%;
  font-size: 13px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.customization_info_Style {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
}
.customizationPageSwitch {
  height: 42px;
  width: 44px;
  margin-left: -130%;
  margin-top: 14%;
  cursor: pointer;
}
/* ///////////////////////////////////////////////////////////// */

/* .bookGroupingTextareaMainDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
} */
.bookGroupingChapterNameText {
  width: 80%;
  height: 40px;
  padding-left: 5px;
  outline: none;
}

.bookGroupingRightArrow {
  width: 22px;
  height: 25px;
  cursor: pointer;
}
.bookGroupingAllPageBottomDiv {
  margin-top: -10%;
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.bookGroupingFromToMain {
  display: flex;
  flex-direction: row;
  margin-left: 7%;
  padding-top: 1%;
}
.bookGroupingFromToMainDiv {
  display: flex;
  flex-direction: row;
  margin-left: 7%;
  padding-top: 1%;
  background-color: rgb(197, 221, 224);
  padding: 1% 5% 1% 2.5%;
  border-radius: 2px;
}
.bookGroupingFroToTextarea {
  max-height: 28px;
  min-height: 28px;
  width: 100%;
  border-radius: 2px;
}
.bookGroupingFroToTextarea::-webkit-scrollbar {
  display: none;
}
.bookGroupingFroToText {
  font-size: 17px;
  padding-right: 5%;
}

.bookGroupingProceedToRecord_disabled {
  width: 18%;
  position: absolute;
  background-color: #698686;
  margin-left: -2%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1% 2% 1% 2%;
  color: white;
  font-size: 19px;
  border-radius: 5px;
  cursor: pointer;
}
.bookGroupingAllPagesNumber {
  display: flex;
  flex-direction: row;
  margin-top: 5%;
  background-color: #276666;
  height: 15%;
  padding: 3%;
  border-radius: 5px 0px 0px 5px;
  color: #fff;
}
.bookCustomization_tick {
  height: 15px;
  width: 15px;
}
.customization_Noparagraph {
  display: flex;
  justify-content: center;
}
.customization_NoparagraphText {
  margin-top: 5%;
  width: 25%;
  background-color: rgb(11, 134, 128);
  text-align: center;
  padding: 1.5%;
  border-radius: 3px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-right: 2%;
}
.customization_NoparagraphTextDisable {
  margin-top: 5%;
  width: 25%;
  background-color: rgb(71, 97, 96);
  text-align: center;
  padding: 1.5%;
  border-radius: 3px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-right: 2%;
  pointer-events: none;
}
.customizationpage_ModaldeleteIcon {
  width: 8%;
  margin-top: 4%;
}
.customizationpage_indexTextDeleteSameDiv_forParaAddtion {
  background-color: rgb(241, 234, 234);
  padding: 5% 7% 5% 7%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.customizationpage_yes_button {
  /* background-color: rgb(71, 156, 149); */
  background-color: rgb(255, 255, 255);
  padding: 1% 0% 1% 0%;
  margin: 2% 0% 2% 2%;
  text-align: center;
  width: 210px;
  border-radius: 5px;
  color: rgb(71, 156, 149);
  cursor: pointer;
  border: solid;
  border-color: #59cbb7;
}
.conformation_modal {
  padding-bottom: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.customizationpage_dilogboxquote {
  text-align: center;
}
.bookGroupingAllChapterListing {
  width: 15%;
  height: 35%;
  display: flex;
  flex-direction: row;
  margin: 2% 4%;
}
.customizationpage_ConfirmProofreadingButton {
  background-color: rgb(234, 239, 241);
  padding: 3% 7% 2% 7%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.conformation_modalProofread {
  /* padding-bottom: 1%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.customization_deleteApage {
  height: 20px;
  width: 20px;
  margin-right: 3%;
}

.customization0PageNopagesAvail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
}
.customization0PageNopagesAvailText {
  list-style: none;
  font-size: 25px;
  color: #174f57;
  font-weight: 500;
}
.customizationpage_mergeAllPara {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 4%;
  margin-top: 2%;
}
.customizationpage_mergeAllparaText {
  background-color: #3f7979;
  width: 20%;
  list-style: none;
  text-align: center;
  padding: 1%;
  border-radius: 3px;
  cursor: pointer;
  color: #ffffff;
}
.customizationpage_mergeAllparaText:hover {
  background-color: #1f5858;
}
.customizationapgeModalCloseButton {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.customizationapgeModalAllCloseDiv {
  display: flex;
  flex-direction: row-reverse;
}
.customizationpagepageSearch {
  list-style: none;
  margin-left: 2%;
  background-color: #ffffff;
  height: 50%;
  padding: 2.5% 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #59cbb7;
  font-size: 16px;
  border: 1px solid #59cbb7;
  border-radius: 100px;
  cursor: pointer;
  margin-top: 1.2%;
  font-weight: 500;
  transition: 0.3s ease-out;
}

/* .customizationpagepageSearch:hover {
  background-color: #59cbb7;
  color: #ffffff;
  transition: 0.3s ease-out;
} */
.customizationpagemergeall {
  background-color: #ffffff;
  border: 1px solid #59cbb7;
  color: #59cbb7;
  display: flex;
  justify-content: center;
  width: 40%;
  padding: 1% 0%;
  border-radius: 30px;
  cursor: pointer;
}

.customizationpagemergeall_disabled {
  background-color: lightgrey;
  border: 1px solid lightgrey;
  color: white;
  display: flex;
  justify-content: center;
  width: 40%;
  padding: 1% 0%;
  border-radius: 30px;
  pointer-events: none;
  cursor: not-allowed;
}

/* .customizationpagemergeall:hover {
  background-color: #59cbb7;
  color: #ffffff;
  transition: 0.3s ease-out;
} */

.customizationpage_pagenationpageNo {
  list-style: none;
  margin-top: 0.7%;
  color: #ffffff;
  font-weight: 500;
}
.customizationpageTotalPages {
  list-style: none;
  margin-top: 0.8%;
  padding-left: 2%;
  font-size: 17px;
  color: #ffffff;
  font-weight: 500;
}
.customizationPageLoadingminDiv {
  display: flex;
  flex-direction: row;
}
.customizationPageLoading {
  margin-top: 10%;
}
