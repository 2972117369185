.verification_container{
    width: 80%;
    height: 80vh;
    display: flex;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    padding: 5%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-width:5px;  
    border-style:solid none solid none;
    border-color: #59cbb7;
}