.createNewAudiobookMainStyle {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100%;
  padding-top: 8%;
}
.CreateNewBook_FirstRow {
  width: 100%;
  background: rgb(77,148,155);
  background: linear-gradient(90deg, rgba(77,148,155,1) 0%, rgba(42,176,96,1) 86%, rgba(77,148,155,1) 100%);
  padding: 12% 0% 12% 0%;
  display: flex;
  flex-direction: column;
  text-justify: center;
}
.projectcreate_dropdown{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 67%;
  margin-left: 13%;
  border: 1px;
}

.CreateNewBook_SecoundRow {
  background-color: #ffffff;
  margin: -35% 10% 0% 55%;
  box-shadow: 0px 0px 2px 0px #5fbac0;
}
.CreateNewBook_SecoundRow input:focus {
  outline: none;
}
.CreateNewBook_createprojectStyle {
  padding-left: 14%;
  font-size: 250%;
  font-weight: bolder;
  color: rgb(247, 240, 240);
}
.CreateNewBook_FormMainStyle {
  padding: 10% 5% 5% 5%;
  display: flex;
  flex-direction: column;
  text-justify: center;
  justify-content: center;
}
.CreateNewBook_ProjectName_Inputid {
  border: none;
  border-bottom: 1px solid;
  margin-left: 4%;
  font-size: 19px;
}
.CreateNewBook_Projectlanguage_Inputid {
  border: none;
  border-bottom: 1px solid;
  font-size: 19px;
}
.CreateNewBook_ProjectName_TextStyle {
  font-size: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #09282b;
  /* padding-bottom:5% ; */
}
.CreateNewBook_inputfeildSameline {
  display: flex;
  flex-direction: row;
  padding-left: 6%;
  padding-bottom: 5%;
  align-items: center;
}
.CreateNewBook_ProjectName_InputStyle {
  padding-left: 4.5%;
}
.CreateNewBook_ProjectLang_InputStyle {
  padding-left: 10%;
}
.CreateNewBook_submitButtonStyle {
  height: 50px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  color: #5fbac0;
  border-radius: 20px;
  border: 0.9px solid #5fbac0;
  font-weight: 500;
  margin: 12% 5% 5% 5%;
  cursor: pointer;
  transition: transform 0.3s ease-out;
}
.CreateNewBook_submitButtonStyle_disabled {
  height: 50px;
  background-color: #85bbc0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  color: rgb(255, 255, 255);
  border-radius: 20px;
  font-weight: 500;
  margin: 12% 5% 5% 5%;
}
.CreateNewBook_SecoundRow_Discribtion {
  display: flex;
  justify-content: center;
  padding-top: 10%;
  flex-direction: column;
  align-items: center;
}
.CreateNewBook_boo2audio {
  color: #15595f;
  font-size: 200%;
  font-weight: 650;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.CreateNewBook_boo2audio_Quote {
  color: #162e2e;
  font-size: 100%;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.CreateNewBook_CloseButton{
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding: 3%;
}
.CreateNewBook_close{
  width: 6%;
  cursor: pointer;
  height: 6%;
}