.spinner {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding-bottom: 10%;
}

.modal_spinner {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10%;
  background: rgba(255, 255, 255, 0.5);
}
.proofread_spinner {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10%;
  background: rgba(255, 255, 255, 0.5);
}
.modal_spinner_new {
  width: 100%;
  height: 100%;
  z-index: 1000;
  top:0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10%;
  background: rgba(255, 255, 255, 0.5);
}

.spinnersm {
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fullscreen_spinner {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10%;
  background: rgba(255, 255, 255, 0.5);
}
