.onPageToolbar_Main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  left: 0%;
  width: 100%;
  background-color: #59cbb7;
  z-index: 999;
  padding-left: 1.6%;
  padding-right: 0.5%;
  height: 60px;
}
.onPageToolbar_Components {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  padding-right: 0.5%;
  width: 90%;
}
.onPageToolbar_Components li {
  padding: 1.2rem;
  color: #4791a0;
  font-size: 1.2vw;
  font-weight: 500;
  cursor: pointer;
}
.onPageToolbar_Heading {
  color: #ffffff;
  font-size: 1.5vw;
  font-weight: 700;
  margin-left: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.onPageToolbar_Logo {
  width: 2vw;
  object-fit: contain;
}
.onPageToolbar_Heading_Main {
  padding: 0.8% 1% 1% 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 15%;
}
.onPageToolbar_avatar {
  width: 1.3vw;
  cursor: pointer;
}

.onPageToolbar_popover_avatar_image {
  width: 15%;
  margin-left: 5%;
  cursor: pointer;
  margin-right: 5%;
}

@media (max-width: 700px) {
  .onPageToolbar_Components {
    display: none;
  }
  .onPageToolbar_Main {
    padding: 2.3% 0px;
  }

  .onPageToolbar_Heading {
    font-size: 4vw;

  }
  .onPageToolbar_Logo {
    width: 5vw;
  }
}
@media (min-width: 700px) {
  .OnapageToolbar_toggle_button {
    display: none;
  }
}
