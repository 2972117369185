@keyframes fall {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 100vh;
    opacity: 1;
  }
}
@keyframes sway {
  0% {
    margin-left: 0;
  }
  25% {
    margin-left: 50px;
  }
  50% {
    margin-left: -50px;
  }
  75% {
    margin-left: 50px;
  }
  100% {
    margin-left: 0;
  }
}
#snow-container {  
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 500ms;
  width: 100%;
}
.snow {
  animation: fall ease-in infinite, sway ease-in-out infinite;
  color: skyblue;
  position: absolute;
}
.MainContainer {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.MainContainer::-webkit-scrollbar {
  display: none;
}
.Swiper_one {
  background-image: url("https://d278admdl7nfkq.cloudfront.net/Images/audiobook_bg_new3.png");
  background-size:100% 100% ;
  background-position: 0% 0%;
  width: 100%;
  margin-top: -3.7vw;
  height: 48.5vw;
}
/* .vericaltext{
  width:1px;
  word-wrap: break-word;
} */
.vertical_semicircle{
  display: flex;
  background-color: #59cbb7;
  width: 50px;
  height: 100px;
  border-radius: 100% / 50%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  right: 0px;
  top: 20%;
  position: absolute;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  white-space: nowrap;
  writing-mode: tb-rl;
  cursor: pointer;
}
.vertical_semicircle:hover {
  height: 530px;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
}
.vertical_semicircle:hover::after {
  content: '   Download how to make Audiobook - eBook';
  font-weight: bold;
  color: white;
}
.homepage_offerbadge{
  position: absolute;
  width: 8%;
  top: 130px;
  left: 31%;
  display: none;
}



.homepage_PartnerCard {
  background-color: #ffffff;
  border: solid;
  border-width: 1px;
  border-color: #000000;
  border-radius: 5px;
  margin-right: 3.5%;
  margin-left: 3.5%;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 10vw;
  /* padding-bottom: 5%; */
  box-shadow: 0px 0px 1px 1px #f1f2f5;
  transition: transform 0.3s ease-out;
}

.homepage_PartnerCard:hover {
  box-shadow: 0px 0px 5px 5px #92949e;
  transition: transform 0.3s ease-out;
  transform: scale(1.01);
}

.homepage_Partnerfoli {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 2%;
  margin-right: 13%;
  margin-left: 3%;
  margin-bottom: 3%;
}

.publish_partner_title{
  width: 55%;
  display: flex;
  text-align: center;
  justify-content: center;
  color: #1d515a;
}

.Listen_text {
  font-size: 2.6vw;
  color: #1d515a;
  font-weight: 500;
}


.publish_partner_container{
  width: 100%;
  padding: 4% 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #87808000;
}

.Main_Text {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
/* .HomepageBannerTextArea {
  padding: 20% 0% 0% 0%;
} */
.Main_TextBuildAudioBook {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
}
.homePageDiscoverDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 9%;
  width: 100%;
}
.Main_text_Style {
  font-size: 3.55vw;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: row;
}
.text_normalThree {
  font-size: 1.3vw;
  text-align: center;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 0px;
  margin-top: 10px;
  margin-bottom: 45px;
}

.swiperScreenReadmore {
  background-color: #ffffff;
  text-align: center;
  font-size: 1.1vw;
  font-family: Arial, Helvetica, sans-serif;
  color: #59cbb7;
  padding: 0.6%;
  border-radius: 5px;
  cursor: pointer;
  width: 27%;
  border: solid;
  border-color: #59cbb7;
  border-width: 1px;
  margin-top: 1%;
  margin-bottom: 1%;
  list-style: none;
}

.Swiper_oneVideo {
  border: solid;
  border-color: #59cbb7;
  padding: 1%;
  background-color: #59cbb7;
  border-radius: 5px;
  background-image: url("https://d278admdl7nfkq.cloudfront.net/Images/videothumbnail.jpg");
  background-size: 100% 100%;
  cursor: pointer;
  width: 29.3vw;
  height: 16vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Swiper_oneVideoWithoutBG {
  border: solid;
  border-color: #59cbb7;
  padding: 1%;
  background-color: #000505;
  border-radius: 5px;
  cursor: pointer;
  width: 29.3vw;
  height: 16vw;
}

.Swiper_oneVideo_youtube {
  border: solid;
  border-color: #59cbb7;
  padding: 0.1%;
  background-color: #59cbb7;
  border-radius: 5px;
  background-size: 100% 100%;
  cursor: pointer;
  width: 29.3vw;
  height: 16vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.youtube_icon{
  width: 5vw;
  margin-top: 2.5vw;
  opacity: 1;
}

.youtube_icon_mobile{
  width: 10vw;
  opacity: 1;
}

.homepage_portfoli {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  margin-right: 13%;
  margin-left: 13%;
  margin-bottom: 3%;
}
.homepage_portCard {
  background-color: #ffffff;
  border: solid;
  border-width: 1px;
  border-color: #f0f0f5;
  border-radius: 5px;
  margin-right: 3.5%;
  margin-left: 3.5%;
  width: 30%;
  padding-bottom: 5%;
  box-shadow: 0px 0px 1px 1px #f1f2f5;
  transition: transform 0.3s ease-out;
}
.homepage_portCard:hover {
  box-shadow: 0px 0px 2px 2px #dfe0e6;
  transition: transform 0.3s ease-out;
  transform: scale(1.01);
}
.homepage_portCardimageOne {
  width: 4.7vw;
  margin-top: 13%;
}
.homepage_portCardImageDiv {
  width: 100%;
  height: 0.5%;
  background-color: #2f3ba3;
}
.homepage_portCardImageDivRow {
  display: flex;
  flex-direction: column;
  margin-top: 10%;
}
.homepage_portCardInnerDiv {
  text-align: center;
  padding-top: 6%;
  font-size: 1.5vw;
  font-weight: 600;
  padding-bottom: 6%;
  color: #308878;
  font-family: "Trebuchet MS";
}
.homepage_portCardInnerDisc {
  list-style: none;
  margin: 2% 8% 8% 8%;
  text-align: center;
  font-size: 99%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.homepage_portReadmoremainDiv {
  display: flex;
  justify-content: center;
  margin-top: 8%;
}
.homepage_portBrowse {
  background-color: #ffffff;
  text-align: center;
  font-size: 1.1vw;
  font-family: Arial, Helvetica, sans-serif;
  color: #43a896;
  padding: 2% 2% 2% 2%;
  border-radius: 5px;
  cursor: pointer;
  width: 50%;
  border: solid;
  border-color: #59cbb7;
  border-width: 0.9px;
  margin-top: 1%;
  margin-bottom: 1%;
  list-style: none;
  /* transition-property: color, background-color;
  transition-duration: 1s; */
}

.homepage_portCardimageThree {
  width: 100%;
  height: 50%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.homepage_portCardimageTwo {
  width: 100%;
  height: 50%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.Main_listen {
  background-color: #f8fbfc;
  padding: 1% 0% 5% 0%;
}

.Listen_text {
  font-size: 2.6vw;
  color: #1d515a;
  font-weight: 500;
}
.listen_caption {
  font-size: 1.3vw;
  color: #1d515a;
  margin-top: 1px;
}
.buildCards_main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2%;
  width: 100%;
}

.sampleAudios{
  width: 15%;
  height: auto;
 
}
.squarecard {
  width: 100%;
  height: 83%;
  transition: all 0.3s ease-out;
  flex-direction: column;
  border-radius: 5px;
  border-width: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  position: relative;
 
}

.squarecards {
  width: auto;
  height: auto;

  padding: 1%;
  
  border-style: ridge;
  
}

.sampleCoverImage_playing{
  opacity: 0.2;
  display: block;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  backface-visibility: hidden;
  object-fit: cover;
}

.samplemiddle_playing{
  transition: .5s ease;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.samplemiddle_playing1{
  transition: .5s ease;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.squarecard:hover {
  transition: all 0.3s ease-out;
  transform: scale(1.02);
}

.squarecard:hover .sampleCoverImage {
  transition: all 0.3s ease-out;
  opacity: 0.2;
}

.squarecard:hover .samplemiddle {
  transition: all 0.3s ease-out;
  opacity: 1;
}

.samplemiddle{
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.sampleCoverImage{
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  backface-visibility: hidden;
  object-fit: cover;
}

.BookSoundFirstDiv {
  background-color: rgb(89, 203, 183);
  height: 45%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.bookSoundFirstDvPlay {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  color: #122526;
  padding-top: 35%;
  padding-right: 8%;
}
.bookSoundFirstDvPlayButton {
  border-radius: 100px;
  padding: 3px 26px 3px 26px;
  text-align: center;
  background-color: rgb(255, 255, 255);
  font-size: 17px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  cursor: pointer;
  color: #59cbb7;
}
/* .bookSoundFirstDvPlayButton:hover {
  border-radius: 100px;
  padding: 3px 26px 3px 26px;
  text-align: center;
  background-color:#59cbb7;
  font-size: 17px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  cursor: pointer;
  color: #ffffff;
} */
.BookSoundSecoundDiv {
  background-color: rgb(255, 255, 255);
  height: 50%;
  padding-bottom: 2%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.Profile_img {
  width: 110px;
  height: 160px;
  margin: -33% 0px auto 20px;
}
.bookSoundFirstDivBookandAuthor {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  color: #122526;
  list-style: none;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  padding-left: 40%;
  padding-top: 5%;
}
.bookSoundFirstDivBookname {
  font-size: 17px;
  padding-left: 10%;
}

.sampleauthordiv{
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: space-between;
  align-items: center;
}
.sampleplaypause{
  width: 80%;
  object-fit: contain;
}


.sampleplaypauses{
  width: 60%;
}

.bookSoundFirstDivBookauthor {
  font-size: 14px;
  font-style: italic;
}

.bookSoundFirstDivBookauthor_end {
  margin-top: 10px;
  font-size: 10px;
  font-style: italic;
  font-weight: bold;
}
.Listen {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-top: 0.1%;
  padding-bottom: 2%;
  flex-direction: column;
  list-style: none;
}
.BuildYourAudioBook_buildCards_main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.BuildYourAudioBookOutline {
  background-color: rgb(241, 241, 241);
  margin: 3%;
  width: 35%;
  border-radius: 10px;
}
.BuildYourAudioBookTopPart {
  background-color: rgb(87, 83, 90);
  padding: 0% 10% 10% 10%;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.BuildYourAudioBookpng {
  width: 28%;
  margin: -13% 10px auto 10px;
  background-color: rgb(30, 131, 131);
  padding: 0% 4% 0% 4%;
  border-radius: 50px;
}
.BuildYourAudioBook_buildcards_headtext {
  list-style: none;
  color: white;
  font-size: 25px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.BuildYourAudioBook_price_audiobookStyle {
  list-style: none;
  color: white;
  font-size: 19px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.BuildYourAudioBookBottomPart {
  border-radius: 10px 10px 0px 0px;
  padding: 2% 0% 0% 0%;
}
.BuildYourAudioBook_manyTextStyle {
  list-style: none;
  padding-top: 5%;
  font-size: 16px;
  color: rgb(0, 2, 7);
}
.BuildYourAudioBook_BuildnowBase {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(87, 83, 90);
  padding: 4%;
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin: 5%;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 15%;
}
.BuildYourAudioBookTopPart_Standard {
  background-color: rgb(54, 53, 56);
  padding: 0% 10% 10% 10%;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.BuildYourAudioBook_BuildnowSandard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(54, 53, 56);
  padding: 4%;
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin: 5%;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 15%;
}
.BuildYourAudioBookTopPart_Premium {
  background-color: rgb(31, 28, 31);
  padding: 0% 10% 10% 10%;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.BuildYourAudioBook_BuildnowPremium {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(31, 28, 31);
  padding: 4%;
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin: 5%;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 15%;
}
.BuildYourAudioBookTopPart_Custom {
  background-color: rgb(30, 131, 131);
  padding: 0% 10% 10% 10%;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.BuildYourAudioBookpng_Custom {
  width: 25%;
  margin: -12% 10px auto 10px;
  background-color: rgb(54, 53, 56);
  padding: 1% 4% 1% 4%;
  border-radius: 50px;
}
.BuildYourAudioBook_Custom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(30, 131, 131);
  padding: 4%;
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin: 5%;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 50%;
}

/* ////////////// responsive////////////// */

.vrBooksMainStyle {
  background-color: #1b1b1b;
  padding-bottom: 2%;
}
.homepagefooterMain {
  color: #d4d4e4;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: 2% 2% 0% 9%;
}
.homepageFooterAudifyz {
  color: #d4d4e4;
  font-size: 19px;
  font-weight: 400;
}
.homepagefootersubheading {
  color: #d4d4e4;
  font-size: 14px;
  border: solid;
  border-color: #d4d4e4;
  border-radius: 4px;
  padding: 5px;
  border-width: 0.7px;
  width: 25%;
  text-align: center;
  margin-top: 2%;
  margin-left: 1%;
  cursor: pointer;
}
.homepagefootersubheading:hover {
  background-color: #3f3a3a;
}
.homepagefooterFollowDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  padding-top: 4%;
}
.homepagefooterfollow {
  color: #d4d4e4;
  list-style: none;
  font-size: 28px;
}
.homepagefooterfollowimages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 1%;
}
.homepagefootersocialmedia {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
}
.homepagePolices {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #d4d4e4;
  list-style: none;
  padding-top: 0.5%;
}
.homepagefooterDivFirstsec {
  display: flex;
  flex-direction: row;
}
.homepagePoliceStyle {
  padding-right: 2%;
}
.homepageStrightLine {
  background-color: #232327;
  margin: 0%;
  height: 0.01px;
}
.homepagefootersocialmedia_tw {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  margin-top: 2px;
  cursor: pointer;
}
.homepagefootersocialmedia_yt {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  margin-top: 2px;
  cursor: pointer;
}
.homepagefootersocialmedia_gplus {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  cursor: pointer;
}
.homepageCompany {
  font-weight: 500;
  font-size: 17px;
  padding-right: 8px;
}
.homepageCompanyUSA {
  padding-top: 1px;
}
.homepageFooterContactDiv {
  color: #d4d4e4;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: 2% 2% 0.5% 9%;
}
.hoempageFooterContusStyle {
  font-size: 19px;
}
.homepagecontactDetails {
  font-size: 14px;
}
.homepageFooterMobile {
  display: flex;
  flex-direction: row;
  margin-left: 0%;
}
.homepageFooterPlaystore {
  height: 38px;
  width: 115px;
  margin-top: 20px;
  border-radius: 5px;
}
.homepageFooterapplestore {
  height: 80px;
  width: 150px;
  margin-top: 10px;
}
/* ///////////////////////////// */
.homepagePriceHeading {
  text-align: center;
  font-size: 30px;
  color: #1b4b55;
  font-weight: 500;
  padding-top: 2%;
}
.homepagePricingMainDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 2% 8% 3% 8%;
  list-style: none;
}

.homepagePricingbannerDiv{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
  margin-bottom: -15px;
}

.homepage_pricing_banner{
  width: 72.5%;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgb(189, 189, 189);
}

.homepageBasicPriceCardStyle {
  width: 24%;
  padding: 1px 1px 1px 1px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgb(189, 189, 189);
  transition: all 0.2s ease-in-out;
}
.homepagepriceDoller {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 0%;
}
.homepage_slicedprice {
  margin-left: 5px;
  font-size: 110%;
  color: rgb(197, 83, 83);
  margin-bottom: -3%;
  margin-right: -30%;
  margin-bottom: -7%;
  text-decoration: line-through;
}
.homepagePrice {
  font-size: 3vw;
  font-weight: 600;
  color: #232333;
  margin: 0%;
}
.homepagepriceDollerSign_Zero {
  padding-top: 10%;
  font-size: 1.5vw;
}
.homepagepriceDollerSign {
  padding-bottom: 10%;
  padding-right: 2%;
}
.homepagePriceingTypesHeading {
  display: flex;
  padding-top: 10%;
  font-size: 25px;
  align-items: center;
  justify-content: center;
  color: #59cbb7;
  font-weight: 500;
}
.homepage_plancard_pricediv {
  padding: 4% 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.homepage_plancard_descdiv {
  padding: 6%;
  color: #606360;
  font-weight: bold;
}
.homepagePriceperbook {
  color: rgb(29, 29, 29);
}
.homepagepricingDetails {
  margin: 2% 7% 0% 8%;
  /* text-align: center; */
  height: 33%;
  font-size: 14px;
  color: #636566;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  word-spacing: 3px;
}
/* //////////////////////////////// */

.homepageStandardPriceCardStyle {
  width: 33.3%;
  border: solid;
  border-color: #eee8e8;
  border-width: 1px;
  box-shadow: 0px 0px 1px 1px rgb(238, 234, 234);
}
.homepagePremiumPriceCardStyle {
  width: 33.3%;
  border: solid;
  border-color: #868585;
  border-width: 1px 1px 1px 0px;
}

.homepagePriceingTypesSubHeading {
  padding: 3% 1% 0% 13%;
  font-size: 15px;
  font-weight: 350;
  color: #232333;
}
.homepagePriceDiv {
  display: flex;
  flex-direction: row;
  padding: 3% 0% 0% 12%;
  width: 100%;
}

.homepagePriceBuildNowDiv {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
.homepagePriceBuildNowText {
  background-color: rgb(24, 104, 104);
  padding: 3% 8% 3% 8%;
  border-radius: 5px;
  border: solid;
  border-width: 1.5px;
  border-color: rgb(24, 104, 104);
  color: #f8fafa;
  cursor: pointer;
  width: 50%;
  text-align: center;
}
.homepagePriceBuildNowText:hover {
  background-color: rgb(255, 255, 255);
  color: #08343d;
}
.homepagePriceingFeatures {
  padding: 5% 1% 5% 5%;
  font-size: 14px;
  font-weight: 550;
  color: #232333;
}
.homepagePriceingFeaturesDis {
  font-size: 14px;
  font-weight: 400;
  color: #232333;
}
.homepagePriceFeaturesDiv {
  display: flex;
  flex-direction: row;
  padding: 1% 1% 4% 13%;
}
.homepagepricingTick {
  width: 7%;
  height: 10%;
  margin-right: 10%;
  padding-top: 1%;
}

.publish_banner_container{
  width: 100%;
  padding: 4% 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #59cbb7;
}

.publish_banner_title{
  width: 55%;
  display: flex;
  text-align: center;
  justify-content: center;
  color: white;
}

.publish_banner_caption{
  width: 55%;
  display: flex;
  text-align: center;
  justify-content: center;
  color: white;
  margin-top: 2%;
  font-size: 1.3vw;
}

.publish_banner_img{
  margin-top: 2%;
  width: 55%;
}

@media (max-width: 700px) {
  .homepagePriceingTypesHeading{
    font-size: 4vw;
  }
  .homepage_offerbadge{
    width: 50px;
  }
  .homepage_portBrowse{
    font-size: 3vw;
  }
  .homepagefooterDivFirstsec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .homepageFooterContactDiv {
    margin: 0%;
    width: 100%;
    justify-content: center;
    text-align: center;
    padding-top: 5%;
  }
  .homepage_Partnerfoli {
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 3rem;
    width: 40%;
    /* display: flex; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  .homepage_PartnerCard {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
    /* margin-left: 3rem; */
    /* display: flex; */
  }
  .homepagefooterFollowDiv {
    width: 100%;
    padding-bottom: 4%;
  }
  .homepagefooterMain {
    width: 100%;
    margin: 0%;
    justify-content: center;
    align-items: center;
  }
  .homepagePolices {
    flex-direction: column;
    align-items: center;
    padding-top: 0%;
  }
  .homepagePricingMainDiv {
    margin: 3% 0% 3% 0%;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .homepageBasicPriceCardStyle {
    width: 80%;
    margin-bottom: 30px;
  }
  .homepageStandardPriceCardStyle {
    width: 80%;
    margin-top: 3%;
  }
  .homepagePremiumPriceCardStyle {
    width: 80%;
    margin-top: 3%;
    border-width: 1px 1px 1px 1px;
    margin-bottom: 2%;
  }
  .Swiper_oneVideo {
    padding: 1%;
    height: 180px;
    width: 200px;
  }
  .Main_TextBuildAudioBook {
    width: 170px;
  }

  .Swiper_oneVideoDiv {
    margin-top: 20%;
    width: 100px;
  }

  .text_normalThree {
    font-size: 8px;
  }

  .swiperScreenReadmore {
    font-size: 13px;
    width: 60%;
  }

  .Main_listen {
    padding: 0%;
  }

  .homepage_portfoli {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .homepage_portCard {
    margin-top: 2%;
    margin-bottom: 2%;
    width: 80%;
    /* height: 250px; */
    /* display: flex;
    flex-direction: row; */
  }

  .homepage_portCardInnerDiv {
    font-size: 4vw;
  }

  .homepage_portCardInnerDisc{
    font-size: 2.5vw;
  }

  .homepage_portCardimageTwo {
    margin-top: 23%;
  }
  .homepage_portCardimageOne {
    width: 20vw;
    margin-top: 23%;
  }

  .homepage_portCardimageThree {
    margin-top: 23%;
  }

  .Listen_text {
    font-size: 4vw;
    margin-top: 4%;
  }

  .publish_banner_title{
    width: 90%;
  }

  .publish_banner_caption{
    width: 90%;
    font-size: 14px;
  }

  .publish_banner_img{
    width: 90%;
  }

  .buildCards_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .sampleAudios{
    width: 60%;
    margin-top: 15%;
  }

  .squarecard {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    box-shadow: 0px 0px 0px 0px rgb(10, 14, 75);
  }

  .sampleauthordiv{
    height: 10px;
  }

  .BookSoundFirstDiv {
    width: 300px;
  }

  .bookSoundFirstDvPlay {
    padding-top: 20%;
  }

  .bookSoundFirstDvPlayButton {
    padding: 1%;
    font-size: 14px;
    width: 25%;
  }

  .BookSoundSecoundDiv {
    width: 300px;
    padding-bottom: 0%;
  }

  .Profile_img {
    width: 26%;
    margin: -20% 0px auto 20px;
  }

  .bookSoundFirstDivBookandAuthor {
    padding-top: 0%;
  }

  .bookSoundFirstDivBookname {
    font-size: 20px;
  }

  .bookSoundFirstDivBookauthor {
    font-size: 16px;
  }

  .Listen_text {
    margin-bottom: 0.5%;
  }

  .BuildYourAudioBook_buildCards_main {
    flex-direction: column;
    align-items: center;
  }

  .BuildYourAudioBookOutline {
    width: 300px;
    margin-bottom: 8%;
  }
  .listen_caption {
    margin-bottom: 4%;
    font-size: 2.5vw;
  }
  .Swiper_one {
    height: 0px;
    background-image: none;
  }


  .Listen_text {
    font-size: 4vw;
    margin-top: 4%;
  }

  .Listen_text {
    margin-bottom: 0.5%;
  }

}

/* new */
.HomePageBannerMain {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  list-style: none;
}
.HomePageBannerVideoAndText {
  display: flex;
  flex-direction: column;
  width: 56.7%;
}
.HomePageBannerTextArea {
  padding: 12% 17.5% 0% 0%;
  width: 100%;
}
.HomePageBannerVideoArea {
  margin-left: 29%;
  margin-top: 10%;
}
.OnapageToolbar_Video_MobileView {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Swiper_oneVideo_Mobile {
  border: solid;
  border-color: #59cbb7;
  padding: 1%;
  background-color: #59cbb7;
  border-radius: 5px;
  background-image: url("https://d278admdl7nfkq.cloudfront.net/Images/videothumbnail.jpg");
  background-size: 100% 100%;
  height: 180px;
  cursor: pointer;
  width: 300px;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Swiper_oneVideo_MobileWithoutBG {
  border: solid;
  border-color: #59cbb7;
  padding: 1%;
  background-color: #000000;
  border-radius: 5px;
  background-size: 100% 100%;
  height: 180px;
  cursor: pointer;
  width: 300px;
  margin-top: 5%;
}

.Swiper_oneVideo_Mobile_youtube {
  border: solid;
  border-color: #59cbb7;
  padding: 0.1%;
  background-color: #59cbb7;
  border-radius: 5px;
  background-size: 100% 100%;
  height: 180px;
  cursor: pointer;
  width: 300px;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 700px) {
  .OnapageToolbar_Video_MobileView {
    display: none;
  }
  .HomePageBannerVideoAndText_Mobile {
    display: none;
  }
}

.homepageChat_Main{
  position: fixed;
  bottom: 19px;
  right: 20px;
  padding: 13px;
  border-radius: 100px;
  overflow: visible;
  display: block;
  z-index: 1;
  background-color: #1d9d87;
}

.homepageChat_img {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

@media (max-width: 700px) {
  .HomePageBannerMain {
    display: none;
  }
  .HomePageBannerVideoAndText_Mobile {
    display: flex;
    flex-direction: column;
    padding: 0% 15% 10% 15%;
    list-style: none;
  }
  .Main_text_Style_Mobile {
    color: #59cbb7;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 24px;
    margin-top: 36%;
    text-align: center;
  }

  .text_normalThree_Mobile {
    font-size: 12px;
    text-align: center;
    color: #59cbb7;
    font-family: Arial, Helvetica, sans-serif;
  }

  .homepagePrice {
    font-size: 6vw;
    font-weight: 600;
    color: #232333;
    margin: 0%;
  }
  .homepagepriceDollerSign_Zero {
    font-size: 4vw;
  }
}