.topDiv {
  height: 100px;
}
.bottomDiv {
  height: 100px;
}
.readmoreSetupMain {
  background: rgb(119, 215, 202);
  background: linear-gradient(
    180deg,
    rgba(119, 215, 202, 0.9167017148656338) 0%,
    rgba(26, 149, 106, 1) 100%
  );
  display: block;
  line-height: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #666;
}
.readmore_div_inner span {
  color: #144351;
  font-size: 24px;
  font-weight: bold;
}
.readmore_div_inner {
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 15px 50px 0;
}

.readmore_div_inner h1 {
  color: #fff;
  font-size: 55px;
  font-weight: 550;
  padding: 7px 0;
}
.readmore_div_inner p {
  color: #fff;
  font-size: 16px;
  width: 80%;
  line-height: 25px;
  font-family: "Helvetica Neue", Helvetica, Arial;
}
.readmore_stepsNav {
  display: flex;
  flex-wrap: wrap;
  font-family: "Open Sans", Helvetica, sans-serif;
}
ul,
ol,
p {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.readmore_stepsNav li {
  margin-right: 1%;
  list-style: none;
}
ol {
  margin-block-start: 0.5px;
  margin-block-end: 1px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
/* .readmore_stepsNav a {
  background: #40c0a9;
  border: 1px solid #ffffff;
  border-radius: 5px;
  display: inline-block;
  font-size: 16px;
  margin-top: 15px;
  padding: 6px 12px;
  text-decoration: none;
  cursor: pointer;
} */
.readmore_stepsNavMainItems {
  background: #40c0a9;
  border: 1px solid #ffffff;
  border-radius: 5px;
  display: inline-block;
  font-size: 16px;
  margin-top: 15px;
  padding: 6px 12px;
  text-decoration: none;
  cursor: pointer;
  color: #ffffff;
  width: 100px;
  text-align: center;
  padding: 10px 15px;
  font-weight: 500px;
}
.readmore_stepsNavItems {
  background: #40c0a9;
  border: 1px solid #ffffff;
  border-radius: 20px;
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
  padding: 6px 12px;
  text-decoration: none;
  cursor: pointer;
  color: #ffffff;
}
.readmore_stepsNavMainItems:hover {
  background: #3e8b7d;
}
.readmore_stepsNavItems:hover {
  background: #3e8b7d;
}
.readmore_stepsNav i {
  color: #ffffff;
  font-style: normal;
  opacity: 0.9;
  padding: 0 12px;
}
.readmoreDescription {
  color: #222222;
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  max-width: 800px;
  margin: 40px auto;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding: 0px;
}
h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 0.6em;
  margin-block-end: 0.6em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.h2-5{
  font-size: 35px !important;
}

.h3-5{
  font-size: 26px !important;
}

.readmoreDescription h3 {
  color: #222222;
  font-family: "Helvetica Neue";
  font-size: 30px;
  line-height: 38px;
  margin-top: 50px;
}
.readmoreDescription h2 {
  color: #59cbb7;
  font-family: "Helvetica Neue";
  font-size: 40px;
  line-height: 48px;
  margin-top: 50px;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.readmoreVideo_player {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 1px rgba(110, 109, 109, 0.35);
  margin: 30px auto;
  max-width: 800px;
  padding: 3px;
  position: relative;
}

.readmoreVideo_player_youtube{
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 1px rgba(110, 109, 109, 0.35);
  margin: 30px auto;
  width: 54vw;
  height: 31vw;
  padding: 3px;
  position: relative;
}

.readmoreDescription ul {
  list-style-type: disc;
}

.readmoreDescription ul,
.readmoreDescription ol {
  color: #222222;
  font-size: 21px;
  font-weight: normal;
  line-height: 32px;
  padding: 0 0 0px 25px;
}

.readmoreDescription li {
  padding-bottom: 15px;
}

.lastli{
  padding-bottom: 5px !important;
}

.readmoreDescription h4 {
  font-family: "Helvetica Neue";
  line-height: 38px;
  font-weight: 600;
  margin-top: 50px;
}

.readmoreRoomImage {
  width: 50%;
  height: 100% !important;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 1px rgba(110, 109, 109, 0.35);
  margin-bottom: 30px;
  max-width: 800px;
  padding: 7px;
  position: relative;
}

.readmoreUSBImage {
  width: 40%;
  height: 100% !important;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0px 0px 15px 1px rgba(110, 109, 109, 0.35);
  margin-bottom: 30px;
  max-width: 800px;
  padding: 0px;
  position: relative;
}

.readmoreACXImage {
  width: 75%;
  height: 100% !important;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 1px rgba(110, 109, 109, 0.35);
  margin-bottom: 30px;
  max-width: 800px;
  padding: 8px;
  position: relative;
}

.readmore_rightchevron{
  height: 15px;
  margin-left: 10px;
}

.readmoreRoomImageMain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;
}

.readmoreBrowseImgDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 3%;
  padding-bottom: 6%;
  width: 96%;
}

.readmoreBrowserImgChrome {
  width: 7%;
  margin-right: 2.5%;
  height: 100% !important;
}

.readmoreSampleRecordMain {
  background-color: rgb(236, 241, 243);
  padding: 3%;
  border-radius: 5px;
  text-align: center;
  margin-top: 5%;
}

.readmoreRecordButton {
  background-color: rgb(255, 255, 255);
  width: 20%;
  background-color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #59cbb7;
  padding: 0.6%;
  border-radius: 5px;
  cursor: pointer;
  border: solid;
  border-color: #59cbb7;
  border-width: 1.5px;
  margin-top: 1%;
  margin-bottom: 1%;
  list-style: none;
}

.readmoreRecordButtonMain {
  display: flex;
  justify-content: center;
}

.readmoreconfetti {
  width: 50%;
  height: 100% !important;
  margin: 5%;
}

.readmorecongratz {
  color: red;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 5%;
  margin-top: -8%;
}

.readmoreaboluteShortcts {
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 8%;
  padding: 100px 0 0 0;
  width: 50px;
  overflow: visible;
  display: block;
}

.readmoreShortcutimg {
  width: 100%;
  height: 100% !important;
  cursor: pointer;
  overflow: hidden;
}

.readmoreShortcutimgDiv {
  padding: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease-out;
  opacity: 0.8;
}

.readmoreShortcutimgDiv:hover {
  transition: transform 0.5s ease-out;
  transform: scale(1.3);
  opacity: 1;
}
.readmore_acx {
  font-weight: 800;
  color: rgb(73, 170, 177);
}

.recordingNextpage {
  border: 3px solid #59cbb7;
  border-radius: 5px;
  margin-bottom: 35px;
  padding: 10px 25px;
}

.recordingSuggestions {
  border: 1px solid #d9d9de;
  border-radius: 5px;
  padding: 25px 25px 10px 25px;
  background-color: #f5f8fa;
}

.readmore_recodingMoreMain {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.readmore_browserCheckModal_MainDiv {
  padding: 2%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4%;
}

.readmore_modalinnerdiv{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.readmore_recodingMoreMainImg {
  width: 35%;
  height: 100% !important;
  border-radius: 2px;
}

.readmore_browserCheckModal_Heading {
  font-size: 25px;
  font-weight: 500;
  color: #59cbb7;
  margin-bottom: 3%;
}

.readmorepage_readmore {
  width: 22px;
  height: 100% !important;
  margin-right: 15px;
}

@media (max-width: 700px) {
  .readmore_div_inner {
    width: 100%;
    padding: 5% 3% 8% 3%;
    display: flex;
    flex-direction: column;
  }
  .readmore_div_inner h1{
    font-size: 13vw;
    text-align: left;
  }
  .readmore_div_inner p{
    text-align: left;
  }
  .readmore_stepNav_container{
    width: 100%;
    display: flex;
    padding: 0%;
  }
  .readmore_stepsNav{
    width: 100%;
    display: flex;
  }
  .readmore_stepsNavMainItems{
    font-size: 4vw;
    width: 20vw;
    text-align: center;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 2vw;
    padding-bottom: 2vw;
  }
  .readmoreDescription {
    padding: 5%;
  }
  .readmoreaboluteShortcts {
    width: 0px;
  }
  .readmoreVideo_player_youtube{
    width: 90vw;
    height: 53vw;
  }
  .readmoreRoomImage {
    width: 80%;
  }
  .readmoreUSBImage {
    width: 80%;
  }
  .readmoreBrowserImgChrome {
    width: 15%;
  }
  .readmoreACXImage {
    width: 85%;
  }
}
