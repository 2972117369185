.projects_MainStyle {
  display: flex;
  flex-direction: column;
}
.projectspage_Headingpart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 4%;
  padding-right: 7%;
  padding-left: 6%;
  padding-bottom: 3%;
  background: rgb(182, 232, 224);
  background: linear-gradient(
    180deg,
    rgba(182, 232, 224, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.projectspage_mainHeading {
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 650;
  color: #59cbb7;
}
.projectspage_headingRightSide {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding-top: 1%;
}
.projectspageHiddenpro {
  margin-left: 3%;
  font-size: 21px;
  font-family: Arial, Helvetica, sans-serif;
  color: #59cbb7;
  cursor: pointer;
  font-weight: 600;
}
.projectspageHiddenpro:hover {
  color: #369785;
}
.projectspage_createnew {
  font-size: 21px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #59cbb7;
  cursor: pointer;
}
.projectspage_createnew:hover {
  color: #369785;
}
.projectspage_secoundSectionmain {
  background-color: #fff;
  padding-bottom: 5%;
}
.projectspage_allprojectsListingDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 2%;
}
.projectpage_SingleProjectMain {
  margin: 2% 3% 1% 3%;
  width: 37%;
  background: rgb(255, 255, 255);
  border-radius: 3px;
  box-shadow: 0px 0px 5px 2px rgb(240, 235, 235);
}
.projectspage_refreshButtionDiv {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding: 2% 2% 0% 0%;
}
.projectspage_help {
  height: 23px;
  width: 23px;
  cursor: pointer;
  margin-left: 2%;
  margin-top: 1px;
  box-shadow: 0px 0px 5px 3px rgb(240, 235, 235);
  border-radius: 25px;
}
.projectpages_hideproject {
  margin-top: 5px;
  margin-left: 8px;
  margin-right: 5px;
}
.projectspage_refresh {
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-top: 3px;
  margin-left: 8px;
  margin-right: 5px;
}
.projectpage_SingleProjectNameStyle {
  width: 80%;
  font-size: 24px;
  color: #59cbb7;
  font-weight: 500;
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0% 0% 0% 10%;
}
.projectpage_DescriptioninDetail {
  font-size: 17px;
  color: #7d8382;
  margin: 0% 0% 0% 10%;
  list-style-type: none;
  display: flex;
  align-items: center;
  width: 30%;
  max-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.projectspage_refreshButtonMainDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2%;
}
.projectcreation_dtoStatusMainStyle {
  cursor: pointer;
  margin-top: 1%;
  width: 90%;
}
.projectcreation_dtoStatusMainStyle:hover{
  box-shadow: 0px 0px 2px 0.5px rgb(192, 191, 191);
  outline: none;
  overflow: hidden;
  border-radius: 100px;
}
.projectpage_RecordOruploadButton {
  background-color: #edf0f2;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #59cbb7;
  padding: 1.5%;
  cursor: pointer;
  margin: auto auto;
  font-weight: 500;
  font-size: 20px;
}
.projectpage_RecordOruploadButton_sysPro {
  background-color: gray;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #ffffff;
  opacity: 0.5;
  padding: 1.5%;
  cursor: not-allowed;
  margin: auto auto;
  font-weight: 500;
  font-size: 20px;
}
.projectpage_RecordOruploadButton_null {
  background-color: #edf0f2;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #59cbb7;
  padding: 1.5%;
  cursor: pointer;
  margin: auto auto;
  font-weight: 500;
  font-size: 20px;
  padding: 4% 0% 3.9% 0%;
}
.projectpage_StatusMain {
  font-size: 18px;
  color: #031213;
  font-weight: 600;
}
.projectpageStatusandBar {
  display: flex;
  flex-direction: column;
  margin-left: 13%;
  margin-bottom: 4%;
  width: 100%;
  width: 70%;
}
.projectpagePopOver {
  padding: 3%;
  font-size: 14px;
  color: #000000;
}

.projectinfopop_innerdiv {
  min-height: 50%;
  padding: 3%;
}

.projectinfopop_caption {
  font-weight: bold;
}

.projectinfopop_desc {
  color: rgb(83, 81, 81);
}
.closeModal {
  height: 31px;
  width: 31px;
  cursor: pointer;
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.projectpage_SingleProjectName {
  background: rgb(202, 220, 222);
  background: linear-gradient(
    180deg,
    rgba(202, 220, 222, 1) 0%,
    rgba(101, 182, 190, 1) 100%
  );
  padding: 2%;
  border-radius: 3px;
}
.projectpage_SingleProjectName_custom {
  background: rgb(202, 220, 222);
  background: linear-gradient(
    180deg,
    rgba(202, 220, 222, 1) 0%,
    rgba(65, 147, 154, 1) 100%
  );
  padding: 2%;
  border-radius: 3px;
}
.projectpage_SingleProjectName_group {
  background: rgb(202, 220, 222);
  background: linear-gradient(
    180deg,
    rgba(202, 220, 222, 1) 0%,
    rgba(51, 128, 135, 1) 100%
  );
  padding: 2%;
  border-radius: 3px;
}
.projectpage_SingleProjectName_record {
  background: rgb(202, 220, 222);
  background: linear-gradient(
    180deg,
    rgba(202, 220, 222, 1) 0%,
    rgba(36, 94, 99, 1) 100%
  );
  padding: 2%;
  border-radius: 3px;
}
.projectpage_SingleProjectName_optimize {
  background: rgb(202, 220, 222);
  background: linear-gradient(
    180deg,
    rgba(202, 220, 222, 1) 0%,
    rgba(24, 76, 80, 1) 100%
  );
  padding: 2%;
  border-radius: 3px;
}
.projectpage_SingleProjectdescriptionStyle {
  font-size: 16px;
  color: #031213;
  margin-left: 1%;
  list-style-type: none;
}
.projectpage_RecordOrcustomButton {
  background-color: #47929a;
  width: 70%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #fff;
  padding: 0.2%;
  cursor: pointer;
  margin: auto auto;
  border-style: solid;
  border-color: #398891;
  border-width: 1.5px;
}
.projectpage_RecordOrcustomButton:hover {
  color: #296268;
  width: 70%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #fff;
  padding: 0.2%;
  cursor: pointer;
  margin: auto auto;
  border-style: solid;
  border-color: #398891;
  border-width: 1.5px;
}
.projectpage_RecordOruploadButton_Null {
  background-color: #839597;
  width: 70%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  color: rgb(41, 39, 39);
  padding: 0.2%;
  cursor: pointer;
  margin: auto auto;
  border-style: solid;
  border-color: #839597;
  border-width: 1.5px;
}
.projectCreation_DocumentuploadModal {
  padding: 0% 5% 5% 5%;
}
.projectCreation_DocumentuploadModalHeading {
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  color: #59cbb7;
  list-style-type: none;
}
.projectCreation_DocumentuploadModalsubheading {
  text-align: center;
  font-size: 16px;
  color: #031516;
  list-style-type: none;
  padding-top: 2%;
}
.projectCreation_DocumentuploadModalnote {
  text-align: center;
  font-size: 17px;
  color: #06393b;
  list-style-type: none;
  padding-top: 1%;
  font-style: italic;
}
.projectcreation_uoloadbutton {
  font-size: 19px;
  background-color: #59cbb7;
  border-radius: 3px;
  margin-left: 27%;
  margin-top: 5%;
  color: #ffffff;
}
.projectpage_CurrentstatusStyle {
  display: flex;
  flex-direction: row;
}
.projectpage_current {
  list-style-type: none;
  padding-top: 3.5%;
  font-size: 14px;
  margin-left: 2%;
}
.projectpage_currentstatus {
  list-style-type: none;
  padding-top: 3%;
  font-size: 95%;
  margin-left: 2%;
  color: #021d1d;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}
.projectcreation_dotimageStyle {
  height: 2%;
  width: 2%;
}
.projectcreation_dotLine {
  border-color: rgb(0, 0, 0);
  width: 10%;
}
.indexedStep {
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background-color: rgba(126, 122, 122, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.indexedStep.accomplished {
  background-color: rgba(0, 116, 217, 1);
}
.projectCreation_statusModalMainDiv {
  padding: 5%;
}
.projectCreation_statusModalHeading {
  font-size: 28px;
  text-align: center;
  color: #59cbb7;
  font-weight: bolder;
  padding-bottom: 5%;
}
.projectCreation_statusModalStatusDiv {
  padding: 5% 10% 2% 10%;
}
.projectCreation_statusmainStyle {
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background-color: rgba(211, 128, 128, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.projectCreation_statusmainStyle.accomplished {
  background-color: rgba(0, 116, 217, 1);
}
.projectpage_closeStatusModal {
  text-align: center;
  background-color: #ffffff;
  width: 50%;
  padding: 1%;
  border-radius: 4px;
  color: #59cbb7;
  font-size: 20px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  cursor: pointer;
  border: 1px solid #59cbb7;
}
.projectpage_closeStatusMainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.projectCreationStatusModal_tr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #76a6aa;
  padding: 0px 10px 0px 10px;
}
.projectCreationStatusModalMain {
  margin: 1% 10% 1% 10%;
}
.projectCreatiion_DocumentuplaodModalLorder {
  align-items: center;
  padding: 4% 10% 0% 10%;
}
.projectCreatiion_DocumentuplaodModalPercentage {
  list-style: none;
  text-align: center;
  padding-top: 2%;
  font-style: italic;
}
.projectCreatiion_DocumentuplaodModalNote {
  list-style: none;
  text-align: center;
  padding-top: 3%;
  color: #061b1b;
}
.projectspagehiddenmodalDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1%;
}
.projectspagehiddenHeading {
  text-align: center;
  color: #0b383a;
  font-size: 24px;
  margin-top: 1%;
  font-weight: 500;
  width: 100%;
  margin-bottom: 2%;
}
.projectspageHiddenCloseButton {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.projectspageHiddenheadingandClose {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.projects_engmodal {
  width: 100%;
  height: 100% !important;
  display: flex;
  flex-direction: row;
  padding: 0% 1% 0% 4%;
  flex-wrap: wrap;
  margin: 3% 1%;
}

.projects_engmodalMain {
  padding: 1%;
  overflow: hidden;
  height: 500px;
  overflow-y: scroll;
}

.projects_engmodalMain::-webkit-scrollbar {
  display: none;
}

.projects_engmodal_card {
  width: 115px;
  height: 150px;
  margin: 20px 13px;
  box-shadow: 0px 0px 3px 3px rgb(245, 243, 243);
  background-position: center;
  background-size: contain;
  border: 1px solid rgb(236, 234, 234);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.projects_engmodal_card_selected {
  width: 115px;
  height: 150px;
  margin: 20px 13px;
  box-shadow: 0px 0px 3px 3px rgb(245, 243, 243);
  background-position: center;
  background-size: contain;
  border: 2px solid #4db19e;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.projects_engmodal_card_selected:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.08);
}

.projects_engmodal_card:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.08);
}

.projects_engmodal_cardfooter {
  margin-left: -0.5px;
  background: rgb(89, 203, 183);
  background: linear-gradient(
    0deg,
    rgba(89, 203, 183, 1) 0%,
    rgba(79, 238, 209, 1) 100%
  );
  /* padding: 15% 1% 1% 5%; */
  color: white;
  border-radius: 0px 0px 4px 4px;
  overflow: hidden;
  transition: 0.5s ease;
  height: 0%;
}

.projects_engmodal_card:hover .projects_engmodal_cardfooter {
  height: 80%;
}

.projects_engmodal_card_selected:hover .projects_engmodal_cardfooter {
  height: 80%;
  margin-bottom: -1%;
}

.projects_engmodal_cardfootertitle {
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
  margin-top: 8%;
  cursor: default;
}

.projects_engmodal_cardfooterdesc {
  font-size: 13px;
  margin-left: 20%;
  margin-top: 10%;
  color: #000000;
}

.projects_engmodal_loading {
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects_engmodal_caption {
  color: #59cbb7;
  font-weight: bold;
  font-size: 120%;
  margin: 3% 0% 0% 5%;
}

.projects_engmodal_footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0% 5% 2% 0%;
}

.projects_engmodal_addbutton {
  background-color: #edf0f2;
  color: #59cbb7;
  font-weight: bold;
  padding: 1% 3%;
  border-radius: 30px;
  box-shadow: 0px 1px 2px 1px lightgray;
  cursor: pointer;
}

.projects_engmodal_addbutton_disabled {
  pointer-events: none;
  background: gray;
  color: white;
  font-weight: bold;
  padding: 1% 3%;
  border-radius: 30px;
  box-shadow: 0px 1px 2px 1px lightgray;
  cursor: pointer;
  opacity: 0.3;
}
.projectsPageToastStyle {
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 42%;
  overflow: visible;
  z-index: 1;
}

.projectspagehidden_MainDiv {
  background-color: #ffffff;
  padding: 1% 1% 1% 3%;
  font-size: 15px;
  border-radius: 5px;
  margin-top: 3%;
  color: #4e4c4c;
}

.projectspagehiddenMain {
  width: 300px;
  background-color: #edf0f2;
  color: #47929a;
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4%;
  font-size: 20px;
  border-radius: 5px;
  border: solid;
  border-width: 0px;
  display: flex;
  flex-direction: column;
}

.projectspagehiddencurrentStatus {
  padding: 2%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
