.SR_main {
  display: flex;
  width: 100%;
  height: 92.5vh;
}

.hiddendiv {
  display: none;
}

.SR_innerdiv {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.SR_innerleft {
  background: rgb(89, 203, 183);
  background: linear-gradient(
    0deg,
    rgba(89, 203, 183, 0.40800070028011204) 0%,
    rgba(255, 255, 255, 0.8925945378151261) 100%
  );
  transition: width 0.4s;
}

.SR_innerright {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.17270658263305327) 0%,
    rgba(255, 255, 255, 0.8925945378151261) 100%
  );
  transition: width 0.4s;
}

.SR_innerleft:hover {
  width: 95%;
}

.SR_innerright:hover {
  width: 95%;
}

.SR_recordbutton {
  padding: 3%;
  background: white;
  border-radius: 100px;
  -webkit-box-shadow: -1px 0px 17px 4px rgba(89, 203, 183, 1);
  -moz-box-shadow: -1px 0px 17px 4px rgba(89, 203, 183, 1);
  box-shadow: -1px 0px 17px 4px rgba(89, 203, 183, 1);
  transition: all 0.4s ease-out;
  cursor: pointer;
}

.SR_innerleft:hover .SR_recordleftbutton {
  background: #59cbb7;
}

.SR_innerright:hover .SR_recordrightbutton {
  background: #59cbb7;
}

.SR_mikeImageLeft {
  width: 50px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px 50px;
  background-image: url("../../images/microphone.png");
}

.SR_mikeImageRight {
  width: 50px;
  height: 50px;
  background-size: 50px 50px;
  background-image: url("../../images/microphone.png");
}

.SR_innerleft:hover .SR_mikeImageLeft {
  background-image: url("../../images/microphone_white.png");
}

.SR_recordleftbutton:hover {
  -webkit-box-shadow: -1px 0px 11px 10px rgba(255, 255, 255, 1);
  -moz-box-shadow: -1px 0px 11px 10px rgba(255, 255, 255, 1);
  box-shadow: -1px 0px 11px 10px rgba(255, 255, 255, 1);
}

.SR_recordrightbutton:hover {
  -webkit-box-shadow: -1px 0px 11px 10px rgba(255, 255, 255, 1);
  -moz-box-shadow: -1px 0px 11px 10px rgba(255, 255, 255, 1);
  box-shadow: -1px 0px 11px 10px rgba(255, 255, 255, 1);
}

.SR_innerright:hover .SR_mikeImageRight {
  background-image: url("../../images/microphone_white.png");
}

.SR_innerdiv_textdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;
}

.SR_innerdivcaption {
  font-size: 300%;
}

.SR_innerdivtext {
  width: 80%;
  font-size: 100%;
  text-align: center;
}

.SR_backimage {
  width: 40px;
  height: 40px;
}

.SR_backbutton {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 100px;
  margin: 50px 0px 0px 40px;
  -webkit-box-shadow: 0px 0px 6px 2px rgba(222, 215, 222, 1);
  -moz-box-shadow: 0px 0px 6px 2px rgba(222, 215, 222, 1);
  box-shadow: 0px 0px 6px 2px rgba(222, 215, 222, 1);
  cursor: pointer;
}

.samplerecording_main {
  display: flex;
  width: 100%;
  background: rgb(89, 203, 183);
  background: linear-gradient(
    0deg,
    rgba(89, 203, 183, 0.40800070028011204) 0%,
    rgba(255, 255, 255, 0.8925945378151261) 100%
  );
  transition: all 0.4s;
  flex-direction: column;
}

.samplerecording_maintopdiv {
  height: 15%;
}

.samplerecording_mainmiddiv {
  height: 55%;
  display: flex;
  justify-content: center;
}

.samplerecording_mainmiddiv_textboxcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  height: 100%;
}

.samplerecording_mainbottomdiv {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.samplerecording_textxtop_caption {
  margin-bottom: 3px;
  font-style: italic;
}

.samplerecordng_maintext {
  background: white;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 2%;
  font-size: 150%;
  -webkit-box-shadow: 0px 0px 6px 2px rgba(222, 215, 222, 1);
  -moz-box-shadow: 0px 0px 6px 2px rgba(222, 215, 222, 1);
  box-shadow: 0px 0px 6px 2px rgba(222, 215, 222, 1);
}

.samplerecording_recordbutton {
  width: 85px;
  height: 85px;
  background: white;
  border-radius: 100px;
  -webkit-box-shadow: -1px 0px 17px 4px rgba(89, 203, 183, 1);
  -moz-box-shadow: -1px 0px 17px 4px rgba(89, 203, 183, 1);
  box-shadow: -1px 0px 17px 4px rgba(89, 203, 183, 1);
  transition: all 0.4s ease-out;
  cursor: pointer;
}

/* .samplerecording_recordbutton:hover{
    background: #59CBB7;
} */

.samplerecording_recordimage {
  width: 100%;
  height: 100%;
  background-size: 45px 45px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../images/microphone.png");
}

.samplerecording_recordbutton:hover .samplerecording_recordimage {
  background-image: url("../../images/microphone_white.png");
}

.samplerecording_stopimage {
  width: 100%;
  height: 100%;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../images/square.png");
}

.samplerecording_recordbutton:hover .samplerecording_stopimage {
  background-image: url("../../images/square_white.png");
}

.silencerecording_main {
  display: flex;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.17270658263305327) 0%,
    rgba(255, 255, 255, 0.8925945378151261) 100%
  );
  transition: all 0.4s;
  flex-direction: column;
}

.silencerecording_textxtop_caption {
  font-style: italic;
  margin-top: 12%;
  margin-bottom: 5%;
}

.silencerecordingwave {
  background: white;
  border: 1px solid #59cbb7;
  border-radius: 10px;
  width: 900px;
  -webkit-box-shadow: 0px 0px 6px 2px rgba(222, 215, 222, 1);
  -moz-box-shadow: 0px 0px 6px 2px rgba(222, 215, 222, 1);
  box-shadow: 0px 0px 6px 2px rgba(222, 215, 222, 1);
  margin-bottom: 20px;
}

.silencerecording_play {
  /* margin-top: 3%; */
  padding: 1% 3%;
  border-radius: 20px;
  background: white;
  -webkit-box-shadow: -1px 0px 5px 2px rgba(89, 203, 183, 1);
  -moz-box-shadow: -1px 0px 5px 2px rgba(89, 203, 183, 1);
  box-shadow: -1px 0px 5px 2px rgba(89, 203, 183, 1);
  cursor: pointer;
  transition: all 0.5s;
}

.sampleplaymargin{
  padding: 0.5% 2%;
}

.SR_timer {
  font-size: 200%;
  color: #59cbb7;
  font-weight: bold;
}

.SR_sampletimer {
  font-size: 200%;
  color: rgb(41, 41, 41);
  font-weight: bold;
  text-align: center;
}

.SR_timercontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10%;
}

.SR_toastclose{
  width: 5px;
  height: 5px;
}

.SR_resulttoast{
  z-index: 1000;
  width: 40%;
  position: absolute;
  left: 38%;
  bottom: 43%;
}

.greendiv{
  background: rgba(89, 203, 183, 1);
}

.greydiv{
  background: lightgrey;
}

.SR_resulttoastbody{

}

/* .silencerecording_play:hover{
    background: #59CBB7;
    color: white;
} */
