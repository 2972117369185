.Payment {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 84%;
}

.Payment_showStatement {
  text-align: center;
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #ffffff;
  margin-top: 0.5%;
}

.payment_heading {
  margin-top: 5%;
  text-align: center;
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 550;
  color: #ffffff;
}

.paymentpage_background {
  background: rgb(89, 203, 183);
  background: linear-gradient(
    180deg,
    rgba(89, 203, 183, 1) 0%,
    rgba(182, 232, 223, 1) 65%,
    rgba(255, 255, 255, 0.950315160243785) 100%
  );
  padding-bottom: 18%;
  margin-bottom: -14%;

}

.payment_emptycontainer {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(128, 128, 128, 0.5);
}

.payment_emptycontainer h1 {
  font-weight: bold;
  margin-bottom: 2%;
}

.payment_emptycontainer h4 {
  font-weight: 300;
  display: flex;
}

.paymentdiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0% 5% 3% 5%;
}

.paymentpagePricingbannerDiv{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
  margin-bottom: 15px;
}

.payment_pricing_banner{
  width: 77.5%;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgb(189, 189, 189);
}

.payment_plancard {
  width: 24%;
  min-height: 95%;
  padding: 1px 1px 15px 1px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgb(189, 189, 189);
  transition: all 0.2s ease-in-out;
}

/* .payment_plancard:hover {
  transition: all 0.6s ease-in-out;
  transform: scale(1.01);
} */

.payment_plancard_pricediv {
  padding: 4% 0%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment_plancard_button {
  background-color: #ffffff;
  margin: 0% 5%;
  padding: 4% 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #59cbb7;
  font-weight: bold;
  cursor: pointer;
  opacity: 1;
  border: solid;
  border-radius: 5px;
  border-color: #59cbb7;
  border-width: 1px;
}

/* .payment_plancard_button:hover {
  color:  #ffffff;
  background-color: #59cbb7;
} */

.payment_plancard_titlediv {
  display: flex;
  padding-top: 10%;
  font-size: 25px;
  align-items: center;
  justify-content: center;
  color: #59cbb7;
  font-weight: 500;
}

.payment_plancard_price {
  font-weight: bold;
  color: rgb(29, 29, 29);
  margin-bottom: -3%;
}
.payment_plancard_slicedprice {
  margin-left: 5px;
  font-size: 110%;
  color: rgb(197, 83, 83);
  margin-bottom: -3%;
  margin-right: -13%;
  margin-bottom: -7%;
  text-decoration: line-through;
}

.payment_plancard_pricecaption {
  color: rgb(29, 29, 29);
  padding-top: 2px;
  align-self: center;
}

.payment_plancard_listdiv {
  background: white;
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 3%;
}

.payment_plancard_listItemDiv_header {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: flex-start;
  color: rgb(53, 51, 51);
}

.payment_plancard_listItemDiv {
  padding: 2.3% 6%;
  color: #959a95;
  font-weight: bold;
  font-size: 80%;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.payment_card_tick {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.payment_plancard_descdiv {
  padding: 6%;
  color: #606360;
  font-weight: bold;
}

.payment_plancard_suppdetails {
  padding: 6%;
  color: #606360;
  font-size: 13px;
  font-weight: 600;
  color: #59cbb7;
}

.paymentReceiptDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
}

.paymentReceiptInnerdiv {
  background: white;
  width: 93%;
  min-height: 75%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.successButton {
  background: rgb(11, 148, 11);
  color: white;
  padding: 2% 10%;
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 1px solid rgb(11, 148, 11);
  margin: 5% 0%;
}

/* .successButton:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.05);
  background: white;
  color: rgb(11, 148, 11);
} */

.FailButton {
  background: rgb(199, 42, 42);
  color: white;
  padding: 2% 10%;
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 1px solid rgb(199, 42, 42);
  margin-bottom: 1%;
}

.FailButton:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.05);
  background: white;
  color: rgb(199, 42, 42);
}

.paymentReceipt_image {
  width: 40px;
  height: 40px;
}

.paypalmodal_mainbody {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(66, 63, 63, 0.596);
  border: none;
}

.paypalmodal {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 2% 0%;
  width: 80%;
  border-radius: 20px;
  background: white;
}

.paypalmodal_close {
  display: flex;
  justify-content: flex-end;
  padding: 1.5%;
  color: grey;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

/* .paypalmodal_close:hover{
  background: #205858;
  color: white;
} */

.paypalmodal_buttoncontainer {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 0% 3%;
}

.paypalmodal_message {
  text-align: center;
  margin-bottom: 5px;
  color: rgb(5, 117, 192);
}

.paypalmodal_loader {
  margin-top: -5%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 77%;
  height: 100%;
  position: absolute;
  background: rgba(128, 128, 128, 0.5);
}

.payment_plancard_listItemDiv_List {
  width: 100px;
}

/* /////////////////////CheckOut Page////////////////////////// */

.paymentpage_checkout_Main {
  display: flex;
  flex-direction: column;
  padding: 0% 6%;
}
.paymentpage_checkout_Heading_Main {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #59cbb7;
  margin: 4% 0%;
  box-shadow: 0px 1px 10px 4px rgb(210, 255, 255);
}
.paymentpage_checkout_Heading {
  font-size: 30px;
  color: #ffffff;
  font-weight: 550;
  padding: 1% 0%;
}
.paymentpage_checkout_DetailsDiv {
  display: flex;
  flex-direction: row;
}
.paymentpage_checkout_RightDiv {
  display: flex;
  flex-direction: column;
  margin: 8% 0% 4% 0%;
  align-items: center;
}
.paymentpage_checkout_LeftDiv {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.paymentpage_checkout_Pro {
  font-size: 17px;
  margin: 0% 3% 1% 2%;
}
.paymentpage_checkout_ProName {
  font-size: 17px;
  margin: 0% 3% 1% 2%;
  font-weight: 500;
}
.paymentpage_checkout_Buttons_Main {
  margin-top: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paymentpage_checkout_plan {
  width: 27%;
  line-height: 30px;
}
.paymentpage_checkout_Pymentdetails {
  margin: 3% 0% 5% 2%;
  font-size: 16.5px;
}
.paymentpage_checkout_Apply_Coupon_Main {
  background-color: rgb(248, 253, 255);
  text-align: center;
  padding: 5% 10%;
  line-height: 35px;
  margin-left: -20%;
}
.paymentpage_checkout_Details {
  margin: 2% 0% 0% 0%;
  font-size: 18px;
  font-weight: 600;
  color: #59cbb7;
}
.paymentpage_checkout_Singleine {
  border: solid;
  border-color: #000000;
  margin: 4% 36% 3% 0%;
  border-width: 0px 0px 1px 0px;
}
.apply_promocode {
  background-color: #ffffff;
  display: flex;
  margin-top: 10%;
  justify-content: center;
  align-items: center;
  color: #59cbb7;
  font-weight: bold;
  cursor: pointer;
  opacity: 1;
  border: solid;
  border-radius: 25px;
  border-color: #59cbb7;
  border-width: 1px;
  width: 60%;
}
.apply_promocode_Main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.apply_promocode_Text {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  border-width: 1px;
  font-size: 18px;
  text-align: center;
  color: #2a8b7a;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}
.payment_checkout_addpromo_Heading {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 7px;
}
.paymentpage_checkout_priceRight {
  margin-top: 0.5%;
}
.paymentpage_checkout_checkOut {
  background-color: #ffffff;
  display: flex;
  padding: 3% 0%;
  margin-bottom: 10%;
  margin-top: 10%;
  justify-content: center;
  align-items: center;
  color: #59cbb7;
  font-weight: bold;
  cursor: pointer;
  opacity: 1;
  font-size: 17px;
  border: solid;
  border-radius: 25px;
  border-color: #59cbb7;
  border-width: 1px;
  width: 250px;
}
.paymentpage_checkout_Invalid_PromoCode {
  color: rgb(255, 0, 0);
  font-size: 14px;
}

/* ///////// Payment sucess //////////// */

.paymentSuccess {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 80%;
  border-radius: 5px;
  background: rgb(243, 252, 252);
}

.paymentpage_Sucess_Heading_Main {
  display: flex;
  justify-content: center;
  padding: 5% 3%;
  background-color: #59cbb7;
  border-radius: 5px 5px 0px 0px;
}

.paymentpage_Sucess_Heading {
  font-size: 33px;
  color: #ffffff;
  font-weight: 600;
}

.paymentpage_Sucess_Heading_Sub {
  color: #ffffff;
  font-size: 30px;
  margin: 15px 5px 0px 0px;
  font-weight: 500;
}

.paymentpage_Sucess_Icon {
  width: 55px;
  height: 50px;
  margin: -15px 0px 0px 3px;
}

.paymentpage_Sucess_Payment_Details_Main {
  margin: 4% 3%;
  background-color: #ffffff;
}

.paymentpage_Sucess_Payment_Details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.paymentpage_Sucess_Details_Heading {
  padding: 3% 4% 1% 4%;
  font-size: 16px;
  font-weight: 500;
}

.paymentpage_Sucess_Details {
  padding: 0% 4% 3% 4%;
  font-size: 15px;
}

.paymentpage_Sucess_Payment_line {
  border: solid;
  border-color: #0d1518;
  margin: 0% 3% 3% 3%;
  border-width: 0px 0px 1px 0px;
}

.paymentpage_Sucess_Button_Main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paymentpage_Sucess_Button {
  padding: 1% 0%;
  background-color: rgb(255, 255, 255);
  width: 30%;
  border-radius: 25px;
  cursor: pointer;
  color: rgb(89, 203, 183);
  font-weight: 500;
  margin-bottom: 4%;
  font-size: 18px;
  border: solid;
  border-color: #59cbb7;
  border-width: 1px;
  text-align: center;
}

.paymentpage_Sucess_Details_Top {
  padding: 3% 7% 1% 7%;
  font-size: 16px;
  font-weight: 500;
}
.paymentpage_Sucess_Payment_top_details {
  padding: 0% 7% 1% 7%;
  font-size: 15px;
  width: 50%;
}

.paymentpage_Sucess_Payment_top_left {
  padding: 0% 7% 1% 7%;
  font-size: 15px;
  color: #000000;
}

.paymentpage_Sucess_Payment_UserDetails {
  padding: 0% 0% 2% 0%;
  font-size: 15px;
  color: #000000;
  width: 35%;
}

.paymentpage_Sucess_UserName_Main {
  display: flex;
  flex-direction: row;
}

.customizationapgeModalAllCloseDiv_Loading {
  padding: 30% 0%;
  display: flex;
  justify-content: center;
}
/* //////////////Payment- fail//////////////////// */
.paymentFail {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 80%;
  border-radius: 5px;
  background: rgb(245, 247, 247);
}

.paymentpage_Fail_Heading_Main {
  display: flex;
  justify-content: center;
  padding: 5% 3%;
  background-color: #a6adad;
  border-radius: 5px 5px 0px 0px;
}
