.ResetPassword {
  margin: 0%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ResetPassword button:focus {
  outline: none;
}

.ResetPassword button input:focus {
  outline: none;
}

.resetpass_container {
  width: 80%;
  height: 80vh;
  display: flex;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  padding: 5%;
  flex-direction: column;
  align-items: center;
  border-width: 5px;
  border-style: solid none solid none;
  border-color: #59cbb7;
}

.resetpass_container h3 {
  color: #356e73;
  font-size: 2.5rem;
}

.reset_captiondiv {
  display: flex;
  width: 80%;
  justify-content: center;
}

.resetdiv_middlesection {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 0%;
  font-weight: 500;
}

.resetdiv_inputdiv {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 0% 2%;
}

.resetdiv_inputdiv input {
  margin: 2% 0%;
  outline: none;
  border: none;
  border-bottom: 1.19px solid #0f393d;
}

.resetdiv_bottomsection {
  width: 80%;
  padding: 0% 2%;
  display: flex;
  justify-content: center;
}

.reset_button {
  width: 45%;
  background: #ffffff;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  padding: 3%;
  color: #59cbb7;
  font-weight: 700;
  font-size: 1.2rem;
  /* outline: none; */
  border-radius: 50px;
  text-align: center;
  border-style: solid;
  border-color: #59cbb7;
  border-width: 1px;
  cursor: pointer;
}

/* .reset_button:hover {
    background: transparent;
    -webkit-transition-duration: 0.4s; /* Safari */
/* transition-duration: 0.4s;
    color: #356e73;
    font-size: 1.2rem;
    outline: none; 
  } */

.reset_button_disabled {
  width: 50%;
  background: grey;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  padding: 3%;
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  /* outline: none; */
  border-radius: 5px;
  text-align: center;
  border-style: solid;
  border-color: grey;
  border-width: 1px;
}
