.MainDiv {
  margin: 0%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainDiv button:focus {
  outline: none;
}
.loginUserred_CloseButton {
  height: 30px;
  width: 30px;
  margin: 10px;
  cursor: pointer;
}
.logincontainer {
  width: 80%;
  height: 80vh;
  display: flex;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.login_left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #59cbb7;
}

/* #login_left_logo{
  width: 30%;
} */

.login_left h5 {
  color: rgb(255, 255, 255);
  padding-bottom: 10%;
  font-size: 15px;
}

.login_right h5 {
  font-weight: bold;
}

.login_left h3 {
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 40px;
}

.provider_button {
  width: 50%;
  /* background: transparent; */
  /* -webkit-transition-duration: 0.4s; Safari */
  /* transition-duration: 0.4s; */
  margin: 5% 0% 0% 0%;
  padding: 2%;
  color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 1.2rem;
  border-radius: 5px;
  text-align: center;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-width: 1px;
  cursor: pointer;
  background-color: #59cbb7;
}

/* .provider_button:hover {
  background: #f2fcfc;
  color: #356e73;
  -webkit-transition-duration: 0.4s; Safari
  transition-duration: 0.4s;
  outline: none;
} */

.login_right {
  width: 50%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background: white;
}

.login_right label {
  font-size: 0.7rem;
  margin-top: 5%;
  margin-bottom: 1%;
}

.login_right input {
  /* width: 60%; */
  border: none;
  border-bottom: 1.19px solid #0f393d;
  outline: none;
  font-size: 0.9rem;
}

.passwordresetmodal_innerdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.passwordresetmodal_innerdiv input {
  /* width: 60%; */
  border: none;
  border-bottom: 1.19px solid #0f393d;
  outline: none;
  font-size: 0.9rem;
}

#password_message{
  display: none;
}

#password_message div{
  font-size: 65%;
  font-weight: bold;
  color: red;
}

.login_userregmodal_buttom {
  background: #ffffff;
  color: #59cbb7;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  margin: 10% 0% 0% 0%;
  padding: 2% 7%;
  /* outline: none; */
  border-radius: 50px;
  border: 1px solid transparent;
  text-align: center;
  cursor: pointer;
  border: solid;
  border-width: 1px;
  border-color: #59cbb7;
}

.login_userregmodal_buttom_disabled {
  background: grey;
  color: #ffffff;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  margin: 10% 0% 0% 0%;
  padding: 2% 7%;
  /* outline: none; */
  border-radius: 50px;
  border: 1px solid transparent;
  text-align: center;
  pointer-events: none;
  opacity: 0.5;
}

/* .login_userregmodal_buttom:hover {
  background: #ffffff;
  color: #59cbb7;
  border: 1px solid #59cbb7;
} */

.login_right_button {
  background: #ffffff;
  /* -webkit-transition-duration: 0.4s; /* Safari 
  transition-duration: 0.4s; */
  margin: 10% 0% 0% 0%;
  padding: 3%;
  color: #59cbb7;
  font-weight: 700;
  font-size: 1.2rem;
  /* outline: none; */
  border-radius: 5px;
  text-align: center;
  border-style: solid;
  border-color: #59cbb7;
  border-width: 1px;
  cursor: pointer;
}

/* .login_right_button:hover {
  background: transparent;
  -webkit-transition-duration: 0.4s; /* Safari 
  transition-duration: 0.4s;
  color: #356e73;
  font-size: 1.2rem;
  outline: none;
} */

.login_right_button_disabled {
  background: #d3d2d2;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  margin: 10% 0% 0% 0%;
  padding: 3%;
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  /* outline: none; */
  border-radius: 5px;
  text-align: center;
  border-style: solid;
  border-color: #dbdbdb;
  border-width: 1px;
}

.unselected_button {
  background-color: #59cbb7;
  width: 15%;
  font-size: 70%;
  padding: 1% 0%;
  color: white;
  font-weight: 500;
  border-radius: 3px;
  text-align: center;
  border: solid;
  border-color: #59cbb7;
  border-width: 1px;
  cursor: pointer;
  margin: 0% 0% 0% 2%;
}

/* .unselected_button:hover {
  background: transparent;
  color: #59cbb7;
} */

.selected_button {
  background-color: #ffffff;
  width: 15%;
  font-size: 70%;
  padding: 1% 0%;
  color: #59cbb7;
  font-weight: 500;
  border-radius: 3px;
  text-align: center;
  border: solid;
  border-color: #59cbb7;
  border-width: 1px;
  cursor: pointer;
  margin: 0% 0% 0% 2%;
}

.forgotpassword {
  font-size: 0.8rem;
  cursor: pointer;
  font-weight: 500;
}

.forgotpassword:hover {
  text-decoration: underline;
}

.category_button {
  background: #356e73;
  padding: 3% 1%;
  border: 1px solid;
  border-radius: 5%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70%;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.category_button:hover {
  background: transparent;
  color: #356e73;
}

.selected_category_button {
  background: transparent;
  padding: 1%;
  border: 1px solid;
  border-radius: 5%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70%;
  font-weight: bold;
  color: #356e73;
}

.password_container {
  margin-top: 5%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.error_message {
  color: red;
  font-weight: bold;
  font-size: 0.7rem;
  margin-left: 5%;
}

.success_message {
  color: green;
  font-weight: bold;
  font-size: 0.7rem;
  margin-left: 5%;
}

@media (max-width: 700px) {
  .logincontainer {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
  }

  .login_left {
    width: 100%;
    padding: 10% 0%;
  }

  .login_right {
    width: 100%;
    padding: 10% 0%;
  }
}

/* 
//////////// */

.signinText {
  text-align: center;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  padding: 7px;
}
.dialogStyle {
  width: 680px;
  max-width: 100%;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%);
  /* z-index: 999; */
  background-color: #eee;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
.dialogCloseButtonStyles {
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  width: 589px;
  height: 50px;
  align-self: center;
  background-color: #1273eb;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
  font-size: 20px;
}
.paragraphSignin {
  text-align: center;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  padding: 1px;
  color: darkslategrey;
}
.Signinbuttons {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  padding-right: 42px;
}
.buttonGoogle {
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  color: black;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 17px;
  padding-right: 90px;
  background-color: white;
  color: black;
  font-size: 19px;
  cursor: pointer;
  padding-left: 60px;
  box-shadow: 1.5px 1.5px #888888;
}
.buttonFacebook {
  margin-left: 13px;
  padding: 10px;
  border-radius: 5px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 17px;
  padding-right: 90px;
  background-color: #3b5998;
  color: white;
  font-size: 19px;
  cursor: pointer;
  padding-left: 60px;
  box-shadow: 1.5px 1.5px #888888;
}
.googleimg {
  height: 26px;
  width: 26px;
  margin-right: 10px;
  margin-left: 10px;
}
.seperator {
  padding-bottom: 10px;
  font-style: italic;
  text-align: center;
}
.lineleft {
  height: 0.1px;
  border-color: rgb(124, 109, 109);
  width: 250px;
  margin-left: 34px;
}
.sameline {
  display: flex;
  justify-content: space-between;
}
.lineright {
  height: 0.5px;
  border-color: rgb(124, 109, 109);
  width: 250px;
  margin-right: 34px;
}
.login {
  padding-left: 30px;
  padding-right: 40px;
}
.signupandforgot {
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 50px;
}
.password {
  color: blue;
  font-size: 14px;
  cursor: pointer;
}
.buttonsocialgoogle {
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  color: black;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 17px;
  padding-right: 80px;
  background-color: white;
  color: black;
  font-size: 19px;
  cursor: pointer;
  padding-left: 50px;
  box-shadow: 1.5px 1.5px #888888;
}
.buttonsocialface {
  margin-left: 13px;
  padding: 10px;
  border-radius: 5px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 17px;
  padding-right: 80px;
  background-color: #3b5998;
  color: white;
  font-size: 19px;
  cursor: pointer;
  padding-left: 50px;
  box-shadow: 1.5px 1.5px #888888;
}
.qwe {
  background-color: red;
}
.signinUppage {
  display: flex;
  justify-content: flex-end;
  margin-right: 14px;
  color: blue;
  cursor: pointer;
  font-size: 16px;
}
.error_message_login {
  color: red;
  font-weight: bold;
  font-size: 0.7rem;
  /* margin-left: 1%; */
}
.loginpagePasswordStrDiv {
  font-weight: bold;
  font-size: 0.7rem;
}

.btnFacebook {
  margin: 5px;
  width: 250px;
  height: 50px;
  border-radius: 3px;
  color: white;
  background: #3b5998;
  border: 0px transparent;
  display: inline-block;
}
.btnGoogle {
  margin: 5px;
  width: 250px;
  height: 50px;
  border-radius: 6px;
  color: rgb(0, 0, 0);
  border: 0px transparent;
  display: flex;
  justify-content: center;
}
.btnFacebook:hover {
  background: #3b5998;
  opacity: 0.6;
}
.btnGoogle:hover {
  opacity: 0.6;
}

.loginpage_emailMandatory_Main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginpage_emailMandatory_inputarea {
  margin: 4% 0% 1% 0%;
  height: 30px;
  border-width: 0px 0px 1px 0px;
  font-style: italic;
  width: 50%;
  font-size: 18px;
}

.loginpage_emailMandatory_inputarea_Main {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.loginpage_emailMandatory_Button_Main {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 4%;
}

.loginpage_emailMandatory_Button {
  padding: 0.7% 0%;
  background-color: rgb(255, 255, 255);
  width: 20%;
  border-radius: 25px;
  cursor: pointer;
  color: rgb(89, 203, 183);
  font-weight: 500;
  margin-bottom: 4%;
  font-size: 18px;
  border: solid;
  border-color: #59cbb7;
  border-width: 1.5px;
  text-align: center;
}

.loginpage_emailMandatory_Button_loading {
  padding: 0.3% 0%;
  background-color: rgb(89, 203, 183);
  width: 20%;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 4%;
  font-size: 18px;
  border: solid;
  border-color: #59cbb7;
  border-width: 1.5px;
  text-align: center;
}

.loginpage_emailMandatory_text {
  color: rgb(238, 6, 6);
  font-size: 15px;
}
