.optimizeaudio {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 92%;
  position: absolute;
  background: rgb(217, 244, 238);
  background: linear-gradient(
    180deg,
    rgba(217, 244, 238, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.optimizeaudio_projectname {
  padding: 3% 3% 3% 4.5%;
  font-size: 27px;
  font-weight: 600;
}

.optimizeaudio_header {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 5% 3% 10% 3%;
}

.optimizeaudio_bookimage {
  width: 120px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgb(192, 189, 189);
}

.optimizeaudio_headertitle {
  font-weight: bold;
  margin-top: 2.5%;
}

.optimizeaudio_headercaption {
  font-weight: 500;
  color: gray;
  margin-bottom: 10px;
}

.optimizeaudio_playerdiv {
  width: 70%;
}

.optimizeaudio_reject_div {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1%;
}

.optimizeaudio_accept {
  margin-top: 15%;
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(170, 169, 169, 0.3);
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  background-color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 21px;
  color: #59cbb7;
  border: solid;
  border-width: 1px;
  border-color: #ffffff;
}

.optimizeaudio_accept_disabled {
  margin-top: 15%;
  pointer-events: none;
  box-shadow: 1px 1px 2px rgba(170, 169, 169, 0.3);
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  background-color: #d6dadc;
  font-weight: bold;
  font-size: 21px;
  color: #ffffff;
  border: solid;
  border-width: 1px;
  border-color: #ffffff;
}

/* .optimizeaudio_accept:hover{
  transition: all 0.2s ease-in-out;
  background: #59cbb7;
  color: #ffffff;
} */

.optimizeaudio_reject {
  font-size: 21px;
  margin-top: 15%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #59cbb7;
  cursor: pointer;
}

.optimizeaudio_reject:hover {
  color: #369785;
}

.optimizeaudio_play {
  display: flex;
  align-items: center;
  justify-content: center;
}

.optimizeaudio_pause {
  background: #59cbb7;
  color: white;
  font-size: 50%;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 5px 2px rgb(240, 235, 235);
  outline: none;
}

.optimizeaudio_maindiv {
  width: 100%;
  padding: 1% 5%;
  overflow-y: scroll;
}

.optimizeaudio_tableheader {
  width: 100%;
  display: flex;
}

.optimizeaudio_tablecaption {
  width: 20%;
  margin: 0% 1.5%;
  color: grey;
  font-weight: bold;
}

.optimizeaudio_tablecaption_number {
  width: 5%;
  margin: 0% 2%;
  color: grey;
  font-weight: bold;
}

.optimizeaudio_tablerow {
  padding: 1% 0%;
  display: flex;
  align-items: center;
  width: 100%;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: lightgreen;
  cursor: pointer;
}

.optimizeaudio_tablerow:hover {
  transition: all 0.2s ease-in-out;
  background: white;
}

.optimizeaudio_tablerow_selected {
  padding: 1% 0%;
  display: flex;
  align-items: center;
  width: 100%;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: lightgreen;
  background: white;
  cursor: pointer;
}

.optimizeaudio_tableitem_number {
  width: 5%;
  margin: 0% 2%;
  color: grey;
  font-weight: 500;
}

.optimizeaudio_tableitem {
  width: 20%;
  margin: 0% 2%;
  color: grey;
  font-weight: 500;
  display: flex;
  cursor: default;
}

.optimizeaudio_tableitemplay {
  width: 30px;
  height: 30px;
  padding-left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-weight: bold;
  font-size: 20px;
  color: #59cbb7;
  transition: all 0.2s ease-in-out;
  border: 2px solid #59cbb7;
  border-radius: 30px;
}

.optimizeaudio_tableitemplay:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.3);
}

.optimizeaudio_tableitempause {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-weight: bold;
  font-size: 20px;
  color: #59cbb7;
  transition: all 0.2s ease-in-out;
  border: 2px solid #59cbb7;
  border-radius: 30px;
}

.optimizeaudio_tableitempause:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.3);
}

.optimizeaudio_tableitem_icon {
  width: 20%;
  margin: 0%;
  color: grey;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.optimizeaudio_download {
  width: 30px;
  height: 30px;
  transition: all 0.2s ease-in-out;
  outline: none;
  cursor: pointer;
}

.optimizeaudio_download:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.3);
}

.optimizeaudio_download_input {
  display: none;
}

.optimizeaudio_tableitem_number_selected {
  width: 5%;
  margin: 0% 2%;
  color: grey;
  font-weight: 500;
  color: #59cbb7;
}

.optimizeaudio_tableitem_selected {
  width: 20%;
  margin: 0% 2%;
  color: grey;
  font-weight: 500;
  color: #59cbb7;
  cursor: default;
}

.optimizeaudio_rowplay {
  width: 15px;
  height: 15px;
}

.OptimizeAudio_wavePlayButton {
  width: 60px;
  height: 60px;
  margin-right: 5px;
  cursor: pointer;
}

.optimizeAudio_Waveaudio_MainDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
}

.optimizeAudio_Waveaudio {
  width: 90%;
  overflow-x: scroll;
}

.optimizeAudio_Waveaudio_Null {
  width: 0%;
}

.optimizeAudio_Waveaudio::-webkit-scrollbar {
  display: none;
}
