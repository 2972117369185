.faqsbackgroudDiv {
  width: 100%;
  height: 280px;
  background: rgb(89, 203, 183);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
}
.faqsbackgroudDivHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.faqsHeading {
  font-size: 40px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  padding-bottom: 0.5%;
}
.faqsSubheading {
  font-size: 17px;
  color: rgb(255, 255, 255);
  font-weight: 300;
  padding-bottom: 1%;
}
.faqsSearchArea {
  border-radius: 3px;
  width: 100%;
  height: 38px;
  /* padding-top: 7px; */
  padding-left: 10px;
  /* color: rgb(190, 190, 190); */
  font-size: 15px;
  margin-top: 2.5%;
  border-width: 0px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.faqsFrequentlyAsked {
  text-align: center;
  font-size: 30px;
  color: rgb(89, 203, 183);
  margin-top: 3%;
  font-weight: 500;
}
.faqsPopularQuestions {
  text-align: center;
  color: rgb(89, 203, 183);
  font-size: 26px;
  margin-bottom: 1%;
  font-weight: 600;
}
.faqSConnectWithUs {
  text-align: center;
  list-style: none;
  padding: 1%;
}
.faqSConnectWithUsHeading {
  font-size: 26px;
  color: rgb(89, 203, 183);
  font-weight: 600;
  margin-bottom: 0.5%;
}
.faqSConnectWithUsSub {
  font-size: 17px;
  color: rgb(0, 3, 7);
  font-weight: 400;
  padding-bottom: 2%;
  font-size: 15px;
}
.faqSConnectWithUsButtonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.faqSConnectWithUsButton {
  padding: 0.7%;
  background-color: rgb(255, 255, 255);
  width: 10%;
  border-radius: 5px;
  cursor: pointer;
  color: rgb(89, 203, 183);
  font-weight: 500;
  margin-bottom: 4%;
  font-size: 18px;
  border: solid;
  border-color: #59cbb7;
  border-width: 1.5px;
}
/* .faqSConnectWithUsButton:hover {
  background-color: rgb(58, 156, 138);
} */
.faqsFrequentlyImage {
  margin-top: 5%;
  width: 50px;
  height: 50px;
}
.faqsFrequentlyHeading {
  list-style: none;
  font-size: 24px;
  text-align: center;
  margin-top: 7%;
  font-weight: 500;
}
.faqsFrequentlyTopicsMain {
  background-color: rgb(255, 255, 255);
  margin: 2%;
  width: 28%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2%;
  box-shadow: 0px 0px 10px 2px lightgrey;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease-out;
}
.faqsFrequentlyTopicsMain:hover {
  transition: transform 0.3s ease-out;
  transform: scale(1.04);
  box-shadow: 0px 0px 10px 5px lightgrey;
}
.faqsFrequentlyAskedTopics {
  display: flex;
  flex-direction: row;
  margin: 3% 15% 3% 15%;
  justify-content: center;
}
.faqsFrequentlySubheading {
  list-style: none;
  padding-top: 2%;
  margin-bottom: 10%;
  text-align: center;
}
.faqsPopularQuestionsDiv {
  margin: 0% 25% 3% 55%;
  list-style: none;
  width: 60%;
}
.faqsPopularQuestionstext {
  color: rgb(12, 54, 73);
  font-size: 15.5px;
  margin-left: 5%;
  cursor: pointer;
  border: solid;
  border-width: 1px;
  border-color: rgb(208, 222, 228);
  margin-bottom: 1%;
  padding: 4%;
  border-radius: 4px;
  transition: transform 0.3s ease-out;
}
.faqsPopularQuestionstext:hover {
  transition: transform 0.3s ease-out;
  transform: scale(1.02);
  border-width: 1px 1px 1px 6px;
  border-color: rgb(89, 203, 183);
  background-color: rgb(251, 253, 253);
}
.faqsbackground_searchmainDiv {
  display: flex;
  flex-direction: row;
  width: 30%;
}
.faq_maginging_glass {
  margin-top: 4%;
  margin-right: 4%;
  height: 24px;
  width: 24px;
}
.help_PageSupportDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.help_PageUnderLine {
  border: solid;
  border-color: #59cbb7;
  margin: 0% 17.5% 3% 17.5%;
  border-width: 0px 0px 1px 0px;
}
.help_PageSupport_HelpButtons {
  color: #070f0e;
  padding: 1% 3% 1% 3%;
  font-size: 17.5px;
  font-weight: 500;
  cursor: pointer;
}
.help_PageSupport_HelpButtons_Selected {
  color: #59cbb7;
  border: solid;
  border-color: black;
  border-width: 0px 0px 5px 0px;
  padding: 1% 3% 1% 3%;
  font-size: 17.5px;
  font-weight: 500;
  cursor: pointer;
  border-color: #59cbb7;
}
.help_CommonQuestions {
  text-align: center;
  color: #59cbb7;
  margin-bottom: 2%;
  font-size: 28px;
  font-weight: 500;
}
.help_CommonQuestionsMainDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 5%;
}
.faqsPopularQuestionsDiv_Main {
  width: 30%;
}
.helpPage_CommonQuestions {
  width: 60%;
  margin-left: 9%;
}
.helpPage_CommonQuestions_Single_Ques {
  font-size: 17px;
  padding: 1% 0%;
  cursor: pointer;
}
.helpPage_CommonQuestions_Single_Ques:hover {
  color: #0581c9;
}

/* ///////////////////////Answers_Page//////////////////////// */

.FAQ_AnswersPageHeading_MainDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #59cbb7;
  height: 280px;
}
.FAQ_AnswersPageHeading_SearchTab {
  display: flex;
  flex-direction: row;
  width: 35%;
  margin-left: -2%;
}
.FAQ_AnswersPage_Heading {
  font-size: 40px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  padding-bottom: 1%;
}
.FAQ_Answers_maginging_glass {
  margin-top: 3.5%;
  margin-right: 2%;
  height: 24px;
  width: 24px;
}
.FAQs_Answers_InnerDiv {
  color: #222222;
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  max-width: 800px;
  margin: 40px auto;
}

.FAQs_Answers_InnerDiv h3 {
  color: #222222;
  font-family: "Helvetica Neue";
  font-size: 30px;
  line-height: 38px;
  padding: 20px 0 20px 0;
}

.FAQs_Answers_InnerDiv h4 {
  color: #222222;
  font-family: "Helvetica Neue";
  font-size: 20px;
  line-height: 38px;
  padding: 20px 0 0px 0;
}

.FAQs_Answers_InnerDiv p {
  color: rgb(68, 65, 65);
  font-size: 17px;
  line-height: 32px;
  padding-bottom: 3px;
  font-family: "Helvetica Neue", Helvetica, Arial;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 0.6em;
  margin-block-end: 0.6em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

h4 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 0.6em;
  margin-block-end: 0.6em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.FAQ_Answers_Helpful_Div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5% 0% 3% 0%;
}

.FAQ_Answers_Helpful_Heading {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 1.5%;
}

.FAQ_Answers_Helpful_YorN {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.FAQ_Answers_Helpful_Yes {
  background-color: #28a2a0;
  border-radius: 3px;
  padding: 7px 25px;
  margin-right: 9px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition-property: color;
}
.FAQ_Answers_Helpful_Yes:hover {
  background-color: #1c8180;
}

.FAQ_Answers_Helpful_No {
  background-color: #e27271;
  border-radius: 3px;
  padding: 7px 25px;
  margin-left: 9px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition-property: color;
}
.FAQ_Answers_Helpful_No:hover {
  background-color: #ca514f;
}

.FAQ_Answers_Line_Sepreation {
  border: solid;
  border-color: #9b9b9b;
  margin: 0% 22% 3% 22%;
  border-width: 0px 0px 1px 0px;
}

/* ///////////////////////////Email_Support//////////////////////// */

.FAQ_Mailsupport_MainDiv {
  display: flex;
  flex-direction: column;
  background: rgb(89, 203, 183);
  background: linear-gradient(
    180deg,
    rgba(89, 203, 183, 1) 0%,
    rgba(202, 238, 232, 1) 30%,
    rgba(255, 255, 255, 1) 100%
  );
  height: 92vh;
  width: 100%;
  overflow-y: scroll;
}

.FAQ_Mailsupport_Outside_Div {
  display: flex;
  flex-direction: row;
}

.FAQ_MailsupportLeft_Div {
  width: 35%;
}

.FAQ_Mailsupport_Heading_MainDiv {
  text-align: center;
  margin: 2% 0% 3% 0%;
}

.FAQ_Mailsupport_Heading {
  font-size: 38px;
  font-weight: 500;
  color: #ffffff;
}

.FAQ_Mailsupport_Subheading {
  font-size: 17px;
  color: #ffffff;
}

.FAQ_MailsupportImage {
  height: 30%;
  width: 61%;
  margin-left: 30%;
  margin-top: 20%;
}

.FAQ_MailsupportRight_Div {
  width: 50%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-bottom: 2%;
}

.FAQ_MailsupportRight_Div::-webkit-scrollbar {
  display: none;
}

.FAQ_MailsupportForm_Firstname {
  border-radius: 5px;
  width: 43.5%;
  height: 50px;
  padding-left: 10px;
  font-size: 15px;
  margin-bottom: 2.5%;
  border: solid;
  border-color: #c0c0c0;
  border-width: 1px;
  margin-right: 4%;
}

.FAQ_MailsupportForm_email {
  border-radius: 5px;
  width: 91%;
  height: 50px;
  padding-left: 10px;
  font-size: 15px;
  margin-bottom: 2.5%;
  border: solid;
  border-color: #c0c0c0;
  border-width: 1px;
}

.FAQ_MailsupportForm_questions {
  border-radius: 5px;
  width: 91%;
  height: 150px;
  padding-left: 15px;
  padding-top: 10px;
  font-size: 15px;
  margin-bottom: 2.5%;
  border: solid;
  border-color: #c0c0c0;
  border-width: 1px;
}

.FAQ_MailsupportForm_UploadFile {
  border-radius: 5px;
  width: 50%;
  height: 50px;
  padding-left: 15px;
  padding-top: 10px;
  font-size: 15px;
  margin-bottom: 2.5%;
  border: solid;
  border-color: #c0c0c0;
  border-width: 1px;
  background-color: #fff;
}

.FAQ_MailsupportForm_UploadFileCompleted {
  border-radius: 5px;
  width: 50%;
  height: 50px;
  padding-left: 15px;
  font-size: 15px;
  margin-bottom: 2.5%;
  border: solid;
  border-color: #c0c0c0;
  border-width: 1px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
}

.FAQ_MailsupportForm_UploadFileCompletedImage {
  height: 20px;
  width: 20px;
  margin: 4.2% 3%;
}

.FAQ_MailsupportForm_Submit {
  padding: 1.3% 0%;
  background-color: rgb(255, 255, 255);
  width: 30%;
  border-radius: 25px;
  cursor: pointer;
  color: rgb(89, 203, 183);
  font-weight: 500;
  margin-bottom: 4%;
  font-size: 18px;
  border: solid;
  border-color: #59cbb7;
  border-width: 1.5px;
  text-align: center;
}

.FAQ_MailsupportFrom_Text {
  font-size: 16px;
  font-weight: 400;
  margin: 2% 1% 2% 0%;
  width: 90%;
}

.FAQ_MailsupportFrom_TechnicalSupport {
  border-radius: 5px;
  width: 50%;
  height: 80px;
  padding-left: 15px;
  padding-top: 10px;
  font-size: 15px;
  margin-bottom: 2.5%;
  border: solid;
  border-color: #c0c0c0;
  border-width: 1px;
  resize: none;
}

.FAQ_Mailsupport_radialButtob_Options {
  display: flex;
  flex-direction: row;
  padding: 1.5% 3%;
  justify-content: space-between;
}

.FAQ_Mailsupport_radialButton_InsideDiv{
  display: flex;
  flex-direction: row;
  width: 20%;
}

.FAQ_MailsupportFrom_TextMain {
  display: flex;
  flex-direction: column;
  border: solid;
  border-color: #c0c0c0;
  border-width: 1px;
  width: 91%;
  margin-bottom: 2%;
  background-color: #fff;
  border-radius: 5px;
}

.FAQ_Mailsupportradialmargin {
  margin-right: 6%;
  cursor: pointer;
  font-size: 90%;
}

.FAQ_MailsupportradialButton {
  width: 11px;
  height: 11px;
  margin: 6% 1% 0% 0%;
  cursor: pointer;
}

.FAQ_mailsupport_Techical_support {
  margin-left: 4%;
  font-size: 15px;
  margin-bottom: 5%;
  width: 39%;
}

.FAQ_mailsupport_Techical_support li {
  text-indent: -1.3em;
}

.FAQ_MailsupportForm_Submit_Main {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 2%;
}

.FAQ_MailsupportForm_uploadMain {
  display: flex;
  flex-direction: row;
}

.FAQ_MailsupportForm_Spinner {
  margin-top: 1%;
  margin-left: 4%;
}

.FAQ_MailsupportSelectProject_Main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.FAQ_MailsupportSelectProject{
  width: 59%;
}

.FAQ_Mailsupport_project_select {
  margin: 0% 0% 3% 2%;
  border: solid;
  border-color: #c0c0c0;
  border-width: 1px;
  height: 40px;
  border-radius: 5px;
  min-width: 100%;
  max-width: 100%;
  cursor: pointer;
}

.FAQ_MailsupportSelectProject_Text {
  margin: 1% 1.5% 0% 0%;
}

.FAQ_Mailsupport_project_option {
  width: 40%;
}

.FAQ_MailsupportModal_TextareaMain {
  background-color: rgb(234, 239, 241);
  padding: 3% 28%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FAQ_MailsupportModal_One_TextareaMain {
  background-color: rgb(234, 239, 241);
  padding: 3% 26.5%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* /////////////////////////Chat Support/////////////////////////// */

.FAQ_chatSupport_MainDiv {
  display: flex;
  flex-direction: column;
  height: 92%;
  width: 100%;
  padding: 3% 13%;
  position: absolute;
}

.FAQ_chatSupport_HeadingDiv {
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: space-between;
}

.FAQ_chatSupport_Heading {
  font-size: 26px;
  font-weight: 500;
}
.FAQ_chatSupport_HeadingButton {
  color: rgb(89, 203, 183);
  border: solid;
  border-radius: 25px;
  text-align: center;
  padding: 0.5% 0.1% 0.7% 0.1%;
  border-width: 1.5px;
  cursor: pointer;
  width: 13%;
}
.FAQ_chatSupport_MessageMainDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3%;
  position: absolute;
  overflow: hidden;
  width: 74%;
  height: 63%;
}

.FAQ_chatSupport_MessageBottom {
  text-align: center;
  background-color: rgb(244, 245, 245);
  padding: 1% 3%;
  border-radius: 5px;
  font-size: 14px;
  margin: 0% 5%;
  color: #4c4c4d;
  margin-top: 46%;
}
.FAQ_chatSupport_MessageLeft {
  width: 35%;
  overflow-y: scroll;
  margin-right: 2%;
  border: solid;
  border-width: 1px;
  border-color: #d7d9da;
}
.FAQ_chatSupport_MessageRight {
  border: solid;
  border-width: 1px;
  border-color: #c0c0c0;
  width: 75%;
  /* overflow-y: scroll; */
}
.FAQ_chatSupport_MessageTopDiv {
  display: flex;
  flex-direction: column;
}

.FAQ_chatSupport_MessageRight::-webkit-scrollbar {
  display: none;
}

.FAQ_chatSupport_MessageLeft::-webkit-scrollbar {
  display: none;
}

.FAQ_chatSupport_Conversation {
  border-right-width: 0px;
  width: 100%;
  height: 100px;
  padding: 0px;
  border-bottom: 1px solid rgb(203, 210, 214);
  border-left: 8px solid rgb(255, 255, 255);
  padding-top: 8%;
  cursor: pointer;
}

.FAQ_chatSupport_Conversation:hover {
  border-left: 8px solid rgb(0, 146, 146);
  background-color: rgb(249, 252, 251);
}

.FAQ_chatSupport_Conversation_Main {
  font-size: 17px;
  color: #020505;
}

.FAQ_chatSupport_Conversation_Sub {
  font-size: 13px;
  margin: 1% 13%;
}

.FAQ_chatSupport_Conversation_chat {
  display: flex;
  flex-direction: row;
}

.FAQ_chatSupport_Conversation_chatImg {
  height: 24px;
  width: 24px;
  margin: 0.3% 3%;
}

.FAQ_chatSupport_MessageRight_EmptyMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

.FAQ_chatSupport_MessageRight_EmptyMainImg {
  width: 100px;
  height: 100px;
}
.FAQ_chatSupport_MessageRight_EmptyMain_Textheading {
  padding-top: 4%;
  font-size: 19px;
  font-weight: 500;
}

.FAQ_chatSupport_MessageRight_EmptyMain_Text {
  padding-top: 2%;
  font-size: 17px;
}

.FAQ_chatSupport_MessageRight_Conversation {
  display: flex;
  flex-direction: column;
}

.FAQ_chatSupport_MessageRight_Conversation_Heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2%;
}

.FAQ_chatSupport_MessageRight_Conversation_Heading_Con {
  font-size: 18px;
  line-height: 1.875rem;
  font-weight: 500;
}

.FAQ_chatSupport_MessageRight_Conversation_Heading_End {
  color: rgb(89, 203, 183);
  border: solid;
  border-radius: 25px;
  text-align: center;
  padding: 0.5% 0.1% 0.7% 0.1%;
  border-width: 1.5px;
  cursor: pointer;
  width: 20%;
  font-size: 14px;
}

.FAQ_chatSupport_MessageRight_Conversation_logo {
  background-color: rgb(193, 191, 197);
  height: 35px;
  width: 35px;
  margin: 4% 10% 0% 3%;
  border-radius: 50px;
  padding: 3px;
}

.FAQ_chatSupport_MessageRight_Conversation_New {
  display: flex;
  flex-direction: column;
  margin: 4.7% 4% 0% 0%;
}

.FAQ_chatSupport_MessageRight_Conversation_Name {
  font-size: 17px;
  font-weight: 500;
}

.FAQ_chatSupport_MessageRight_Conversation_Send {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1%;
  padding: 3%;
  border-top: 1px solid rgb(203, 210, 214);
}

.FAQ_chatSupport_MessageRight_Conversation_input {
  border-radius: 5px;
  width: 90%;
  height: 45px;
  padding-left: 10px;
  font-size: 15px;
  border: solid;
  border-color: #c0c0c0;
  border-width: 1px;
}

.FAQ_chatSupport_Conversation_chat_New {
  display: flex;
  flex-direction: row;
  height: 265px;
  overflow: scroll;
}

.FAQ_chatSupport_Conversation_chat_New::-webkit-scrollbar {
  display: none;
}

.FAQ_chatSupport_MessageRight_Conversation_send {
  height: 35px;
  width: 35px;
  margin: 1% 1% 0% 3%;
  cursor: pointer;
}

/*///////////////////////// Resposive /////////////////////////////*/

@media (max-width: 700px) {
  .faqSConnectWithUsButton {
    width: 30%;
  }
  .faqSConnectWithUsSub {
    padding-bottom: 6%;
    font-size: 14px;
  }
  .faqSConnectWithUsHeading {
    font-size: 23px;
    margin-bottom: 3%;
  }
  .faqsPopularQuestionstext {
    font-size: 15px;
    margin-bottom: 5%;
  }
  .faqsPopularQuestions {
    font-size: 24px;
    margin-bottom: 4%;
  }
  .faqsFrequentlyHeading {
    font-size: 20px;
    margin-top: 7%;
    font-weight: 500;
  }
  .faqsFrequentlyAskedTopics {
    flex-direction: column;
  }
  .faqsFrequentlyAsked {
    font-size: 22px;
    margin-top: 8%;
    margin-bottom: 8%;
  }
  .faqsbackgroudDiv {
    height: 250px;
    flex-direction: column;
  }
  .faqsHeading {
    font-size: 28px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    padding-bottom: 0.5%;
  }
  .faqsbackgroudDivHeading {
    margin-left: 0%;
    width: 100%;
    text-align: center;
  }
  .faqsSearchArea {
    width: 70%;
    margin-top: 10%;
  }
  .faqsbackground_searchmainDiv {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .faq_maginging_glass {
    margin-top: 10%;
    margin-right: 2%;
  }
  .faqsFrequentlyTopicsMain {
    margin-bottom: 10%;
    width: 100%;
  }
  .faqsPopularQuestionsDiv{
    width: 100%;
    margin: 3% 0% 0% 15%;
  }
  .helpPage_CommonQuestions_Single_Ques{
    font-size: 15.3px;
    padding-left: 2%;
    padding-right: 2%;
  }
  .FAQs_Answers_InnerDiv h3 {
    padding-left: 2%;
    padding-right: 2%;
  }
  .FAQs_Answers_InnerDiv h4 {
    padding-left: 2%;
    padding-right: 2%;
  }
  .FAQs_Answers_InnerDiv p {
    padding-left: 2%;
    padding-right: 2%;
  }
  .FAQ_AnswersPageHeading_MainDiv {
    padding: 0 3%;
    text-align: center;
  }
  .FAQ_AnswersPage_Heading {
  }
}
