.Main_voice {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
  position: absolute;
  background: rgb(217, 244, 238);
  background: linear-gradient(
    180deg,
    rgba(217, 244, 238, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  padding-top: 20px;
}

.disabled_div{
  pointer-events: none;
  opacity: 0.5;
}

.Pages_voice {
  background: transparent;
  width: 18%;
  height: 97%;
  overflow-y: scroll;
  transition: all 0.2s ease-in-out;
  opacity: 0.6;
  /* filter: blur(20px);
  -webkit-filter: blur(20px); */
}
.Pages_voice::-webkit-scrollbar {
  display: none;
}
.Pages_voice:hover {
  /* width: 20%; */
  margin-top: 1%;
  /* height: 96%; */
  transition: all 0.2s ease-in-out;
  transform: scale(1.05);
  opacity: 1;
  filter: blur(0px);
  -webkit-filter: blur(0px);
}
.recordingSection {
  display: flex;
  flex-direction: column;
  padding-top: 3.5%;
  background-color: transparent;
  height: 100%;
  transition: all 0.2s ease-in-out;
}
.recordingsection_sectiontwo {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0% 0% 0% 0%;
  height: 70%;
  /* background-color: red; */
}
.paragraph {
  background: transparent;
  width: 15.5%;
  overflow-y: scroll;
  padding-top: 1%;
  padding-left: 1%;
  opacity: 0.6;
  transition: all 0.2s ease-in-out;
  overflow-wrap: break-word;
  /* filter: blur(20px);
  -webkit-filter: blur(20px); */
}
.paragraph::-webkit-scrollbar {
  display: none;
}
.paragraph:hover {
  margin-top: 1%;
  transition: all 0.2s ease-in-out;
  transform: scale(1.05);
  opacity: 1;
  filter: blur(0px);
  -webkit-filter: blur(0px);
}
.paragraph_voice_recording {
  width: 100%;
  padding: 10%;
  transition: transform 0.3s ease-out;
  font-size: 15px;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  margin-bottom: 5%;
  overflow-wrap: break-word;
}
/* .paragraph_size_rightscroll {
  width: 95%;
  padding: 10%;
  transition: transform 0.3s ease-out;
  font-size: 15px;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
} */
.paragraph_voice_recordingSelected {
  width: 100%;
  padding: 10%;
  transition: transform 0.3s ease-out;
  font-size: 15px;
  background-color: rgb(238, 247, 253);
  border-radius: 4px;
  margin-bottom: 5%;
  overflow-wrap: break-word;
}
/* .paragraph_size_rightscrollSlected {
  width: 95%;
  padding: 10%;
  transition: transform 0.3s ease-out;
  font-size: 15px;
  background-color: rgb(238, 247, 253);
  border-radius: 4px;
} */
.paragraph_size_rightscrollSlected:hover {
  /* transform: scale(1.01); */
  border-color: #a19488;
  box-shadow: 0px 0px 3px 0px #9b9cac;
}
.paragraph_size_rightscroll:hover {
  /* transform: scale(1.01); */
  border-color: #a19488;
  box-shadow: 0px 0px 3px 0px #9b9cac;
}
.leftpagescroll{
  background-color: transparent;
}
.docker_button{
  display: flex;
    cursor: pointer;
    position: absolute;
    top: 15px;
    left: 0%;
    width: 2.1%;
    border-radius: 0px 15px 15px 0px;
    justify-content: center;
    padding: 12px;
    background-color: #59cbb7;
}
.image_size_leftscroll {
  width: 95%;
  height: 100% !important;
  box-shadow: 0px 0px 2px 1px lightgrey;
  border-radius: 5px;
}
.image_size_leftscrollSelected {
  width: 95%;
  height: 100% !important;
  transition: transform 0.3s ease-out;
  border: solid;
  border-color: #59cbb7;
  border-width: 3px;
  border-radius: 5px;
}
.pages_layout {
  width: 80%;
  height: 50%;
  margin-left: 12%;
  margin-top: 8%;
}

.voices_mainparastyle{
  height: 90%;
  overflow-y: scroll;
  overflow-wrap: break-word;
}
.rectangleBox {
  height: 100%;
  width: 85%;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  box-shadow: 0px 0px 4px 0px lightgrey;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  display: block;
  padding: 1% 3% 3% 3%;
  background-color: #ffffff;
  
}
.recordingpage_rectangleboxHeading{
  text-align: center;
  font-size: 17.5px;
  box-shadow: 0px 0px 2px 0px rgb(248, 249, 250);
  font-weight: bold;
  color: #818185;
}
.voices_mainparastyle::-webkit-scrollbar {
  display: none;
}
.RecordButton {
  padding: 3.5%;
  border-radius: 100px;
  cursor: pointer;
  border-color: #5a5999;
  box-shadow: 0px 0px 10px 4px lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-out;
  background: #ffffff;
}
.RecordButton:hover {
  transition: all 0.3s ease-out;
  transform: scale(1.05);
  border-color: #643a16;
  box-shadow: 0px 0px 10px 4px #59cbb7;
}
.RecordButtonNotrecording {
  padding: 5%;
  border-radius: 100px;
  cursor: pointer;
  border-color: #59cbb7;
  box-shadow: 0px 0px 10px 4px #59cbb7;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-out;
  position: relative;
}


.mikeImage {
  width: 35px;
  height: 35px;
}

.wave_playButton {
  width: 100px;
  height: 38px;
  padding: 10px 0px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.384);
  font-size: 80%;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: all 0.3s ease-out;
}

.wave_playButton_active{
  background: #30b8ee7e;
}

.wave_playButton:hover {
  transition: all 0.3s ease-out;
  background: #30b8ee7e;
  border-color: #643a16;
  cursor: pointer;
}

.wave_play{
  width: 15px;
}

.waveloadclass {
  width: 100%;
  height: 100px;
  background: transparent;
  display: flex;
  justify-content: center;
  margin-top: -10%;
}

.editedwaveloadclass {
  width: 100%;
  height: 130px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-out;
}

/* .editedwaveloadclass:hover{
  box-shadow: 0px 0px 5px 1px #020425;
} */

.playButton {
  padding: 12px;
  border-radius: 100px;
  cursor: pointer;
  border-color: #5a5999;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
  transition: transform 0.3s ease-out;
}
.playButton:hover {
  transition: transform 0.3s ease-out;
  transform: scale(1.01);
  border-color: #643a16;
  box-shadow: 0px 0px 3px 0px #020425;
  cursor: pointer;
}
.mic {
  height: 31px;
  width: 31px;
}
.record_ButtonsSameline {
  width: 85%;
  margin-left: 3.54%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 2% 15%;
}

.textAreaHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  background: lightgray;
  width: 25%;
  margin-left: 3.54%;
  margin-top: -3.5%;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
}

.viewbutton{
  display: flex;
  width: 100%;
  opacity: 0.5;
  justify-content: center;
  padding: 2%;
  cursor: pointer;
}

.viewbutton_selected{
  display: flex;
  width: 100%;
  opacity: 0.5;
  justify-content: center;
  padding: 2%;
  background-color: white;
  opacity: 1;
  pointer-events: none;
}

.viewbutton:hover{
  opacity: 1;
}

.textAreaFooter {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0.5% 2%;
  background: lightgray;
  width: 85%;
  margin-left: 3.54%;
  margin-top: -3.5%;
  border-radius: 0px 0px 10px 10px;
}

.textAreaFooter:hover {
  transition: all 0.3s ease-out;
  padding: 0.8% 2%;
  background: gray;
}

.textAreaFooter_icon {
  width: 30px;
  height: 30px;
}

.voices_timer {
  display: flex;
  justify-content: center;
  width: 85%;
  font-size: 120%;
  color: #59cbb7;
  font-weight: bold;
  margin-left: 3.54%;
  margin-top: 3%;
}

.voices_timer_empty {
  display: flex;
  justify-content: center;
  width: 85%;
  font-size: 120%;
  color: transparent;
  font-weight: bold;
  margin-left: 3.54%;
  margin-top: 3%;
}

.voices_wavebutton {
  align-self: flex-end;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  margin-top: -0.2%;
  color: #1b7e85;
  cursor: pointer;
}

.voices_wavebutton:hover {
  transition: all 0.3s ease-out;
  transform: scale(1.1);
}

.voices_wavebutton_disabled {
  align-self: flex-end;
  text-align: center;
  border-radius: 5px;
  margin-top: -0.2%;
  color: lightgrey;
  cursor: not-allowed;
}

.modal-90w {
  max-width: 80% !important;
}

.voices_wave_modal {
  padding: 0% 10px;
  width: 100%;
}

.voices_wavemodal_title{
  font-size: large;
  font-weight: bold;
  color:grey;
}

.voices_wavemodal_wavediv {
  display: flex;
  flex-direction: column;
}

.voices_waveandbuttons_container{
  background: rgb(5,17,70);
background: linear-gradient(90deg, rgba(5,17,70,1) 0%, rgba(34,51,120,1) 49%, rgba(5,17,70,1) 100%);
border-radius: 20px;
}

.voices_wavecontainer{
  margin: 40px 30px 40px 30px;
  width: 95%;
  border-radius: 10px;
  background: rgba(8, 2, 2, 0.099);
}

#waveform{
  width: 100%;
}
#waveform_timeline{
  width: 100%;
}
.voices_wave_buttonscontainer{
  width: 100%;
  display: flex;
  padding: 1%;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  margin:-10px 0px 30px 0px;
}

.voices_wave_popoverdiv{
  padding: 10px;
}

.wavesurfer-handle{
  border-style: solid;
  border-color: #5FE1FA;
  border-width: 0px 3px;
}

.voices_wavemodal_textcaption {
  width: 100%;
  padding: 0% 0% 1% 0%;
  color: black;
  font-size: 15px;
}
.voicepagewaveNoplayButton {
  margin-right: 3%;
}
.voices_wavemodel_textdiv {
  padding: 2%;
  width: 100%;
  max-height: 140px;
  margin: 0% 3% 3% 0%;
  box-shadow: 0px 0px 4px 0px #010f1b;
  border-radius: 5px;
  overflow-y: scroll;
  overflow-wrap: break-word;
}
.voices_wavemodel_textdiv::-webkit-scrollbar {
  display: none;
}
.nextAndPreviusMain {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.previous_style_hidden {
  width: 17%;
  height: 60%;
  background: transparent;
}
.previous_style {
  width: 17%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1% 2%;
  transition: all 0.3s ease-out;
  cursor: pointer;
  border-radius: 30px;
  box-shadow: 0px 0px 4px 0px #59cbb7;
  font-size: 80%;
  font-weight: bold;
}
/* .previous_style:hover {
  transition: all 0.3s ease-out;
  transform: scale(1.02);
  background: #59cbb7;
  color: white;
  box-shadow: 0px 0px 5px 1px #59cbb7;
} */

.prevImage {
  width: 18px;
  height: 18px;
}

.next_style {
  width: 17%;
  padding: 0px;
  color: #3e2d7c;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: transform 0.3s ease-out;
  border: solid;
  border-radius: 30px;
  border-width: 1.3px;
  background-color: rgb(249, 252, 251);
  margin-bottom: 10px;
}
.next_style_DeSilence {
  padding: 3px 8px;
  color: #3e2d7c;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: transform 0.3s ease-out;
  border: solid;
  border-radius: 30px;
  border-width: 1.3px;
  background-color: rgb(249, 252, 251);
  margin-bottom: 10px;
}
.next_styleOnrecord {
  width: 17%;
  padding: 0px;
  color: #887abb;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: transform 0.3s ease-out;
  border: solid;
  border-radius: 30px;
  border-width: 1.3px;
  background-color: rgb(249, 252, 251);
  margin-bottom: 10px;
}
.next_style:hover {
  transform: scale(1.03);
  color: rgb(110, 15, 15);
}

.waveconfirm_class {
  width: 100px;
  height: 38px;
  padding: 10px 0px;
  border-radius: 100px;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: all 0.3s ease-out;
}

.Text_To_Read {
  font-size: 24px;
  word-spacing: 6px;
}
.plusandminusMainStyle {
  display: flex;
  flex-direction: column;
}
.plusandminusBorderLeft {
  padding: 7px;
  background-color: #ffffff;
  border-radius: 15%;
  color: rgb(36, 32, 32);
  font-size: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-bottom: 50%;
  cursor: pointer;
  background-color: #fdfdfd;
  border: solid;
  border-color: transparent;
  border-width: 1.5px;
  box-shadow: 0px 2px 4px 0px lightgrey;
}
.plusandminusBorderLeft:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-out;
  box-shadow: 0px 0px 4px 2px lightgrey;
}
.plusandminusBorderRight {
  padding: 7px;
  background-color: #ffffff;
  border-radius: 15%;
  color: rgb(0, 0, 0);
  font-size: 100%;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  background-color: #ffffff;
  border: solid;
  border-color: transparent;
  box-shadow: 0px 2px 4px 0px lightgrey;
  border-width: 1.5px;
  transition: all 0.3s ease-out;
}
.plusandminusBorderRight:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-out;
  box-shadow: 0px 0px 4px 2px lightgrey;
}
.fontresize {
  width: 25px;
  height: 25px;
}
.fontresize_lg {
  width: 19px;
  height: 19px;
}
.fontresize_sm {
  width: 20px;
  height: 20px;
}
.magingingglass {
  margin-top: 2%;
  margin-right: 2%;
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.voiceleftsideSelectedImage {
  width: 100%;
  height: 100% !important;
}
.voiceleftsideSelectedImageMain {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: row;
}
.voiceleftsideSelectedImageClose {
  background-color: #1b7e85;
  height: 60px;
  padding: 20px 5px 0px 5px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 0px 0px 5px 5px;
  margin-left: -13%;
  margin-top: -0.5px;
}
/* .voiceleftsideSelectedImageClose:hover {
  transition: transform 0.3s ease-out;
  transform: scale(1.04);
} */
.voiceleftsidemagnifinggalssmainStyle {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 95%;
  padding-left: 4%;
  background-color: rgba(3, 0, 0, 0.27);
  border-radius: 0px 0px 5px 5px;
  margin-top: -11.5%;
  padding-bottom: 2%;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: #ffffff;
  transition: transform 0.3s ease-out;
}
.customizationLeftViewpgetext {
  margin-top: 1.5%;
}
.voiceleftsidemagnifinggalssmainStyle:hover {
  background-color: rgba(3, 0, 0, 0.5);
  transition: all 0.3s ease-out;
}
.voicepage_pageNumber {
  height: 10%;
  font-size: 13px;
  color: rgb(255, 255, 255);
  background-color: #59cbb7;
  border-radius: 50px;
  padding: 0% 3% 0% 2%;
}
.voices_page_top {
  margin-bottom: -13%;
  padding: 0% 1% 0% 2%;
  width: 93%;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.projectcreation_dotStatusMainStyle {
  display: flex;
  flex-direction: row;
}
.Voicepagcheckbox {
  height: 18px;
}
.voicepageCompletercordingbutton {
  background-color: #ffffff;
  border: 1px solid #59cbb7;
  color: #59cbb7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  padding: 1.5% 0%;
  margin-right: 20%;
}
/* .voicepageCompletercordingbutton:hover {
  background-color: #59cbb7;
  color: #ffffff;
  transition: 0.3s ease-out;
} */
.voicepageCompletercordingbutton_disabled {
  background-color: lightgray;
  border: 1px solid lightgrey;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-radius: 30px;
  font-size: 16px;
  padding: 1.5% 0%;
  cursor: not-allowed;
  margin-right: 20%;
}
.voices_waveform {
  margin-top: -18.8%;
  width: 150%;
}
.voicesWavegifContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stop {
  height: 31px;
  width: 31px;
}
.recordingpageparagraphconformation {
  height: 18px;
  margin-top: 5%;
  margin-left: -11%;
}
.VoicePage_Heading_Div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 7%;
  background: rgba(5, 5, 5, 0.5);
  transition: all 0.4s ease-in-out;
  opacity: 0.9;
  margin-bottom: 0.1%;
}
.VoicePage_Heading_Div:hover {
  transform: translateY(0);
  opacity: 1;
  width: 100%;
}
.Voicenpage_pagenation {
  padding-left: 2%;
  display: flex;
  align-items: center;
  width: 43%;
  border-color: #05272c;
  font-size: 17px;
}

.voice_footer_end {
  width: 30%;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
}
.Voicepage_pagenationpageNo {
  list-style: none;
  margin-top: 0.5%;
  color: #ffffff;
  font-weight: 500;
}
.Voicepage_pagenationInput {
  margin-top: 1%;
  height: 25px;
  margin-left: 0%;
  font-size: 15px;
  color: #464949;
  padding-left: 1%;
  width: 10%;
}
.VoicepageTotalPages {
  list-style: none;
  margin-top: 0.8%;
  padding-left: 2%;
  font-size: 17px;
  color: #ffffff;
  font-weight: 500;
}
.VoicepagepageSearch {
  list-style: none;
  margin-left: 3%;
  background-color: #ffffff;
  height: 50%;
  padding: 2% 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #59cbb7;
  font-size: 15px;
  border: 1px solid #59cbb7;
  border-radius: 100px;
  cursor: pointer;
  margin-top: 1.2%;
  font-weight: 500;
  transition: 0.3s ease-out;
}

.voices_pie_modal {
  padding: 5%;
}

.voices_infomodal_header {
  width: 100%;
  text-align: center;
  font-size: 180%;
  font-weight: bold;
}

/* .VoicepagepageSearch:hover {
  background-color: #59cbb7;
  color: #ffffff;
  transition: 0.3s ease-out;
} */
