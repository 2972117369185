.side_drawer {
  height: 100%;
  background: white;
  /* box-shadow: 2px 0px 5px rgba(0,0,0,0.5); */
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side_drawer.open {
  transform: translateX(0);
}

.side_drawer ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 5%;
}

.sideDrawer_item {
  font-size: 19px;
  width: 100%;
  padding: 10px 20px;
  border-radius: 3px;
  color: rgb(39, 129, 122);
}

.sideDrawer_item:hover {
  background-color: rgb(219, 235, 235);
  color: rgb(28, 119, 112);
  cursor: pointer;
}

.side_drawer a {
  color: rgb(28, 119, 112);
  text-decoration: none;
}

/* .side_drawer a:hover {
   color: rebeccapurple; 
} */

@media (min-width: 700px) {
  .side_drawer {
    display: none;
  }
}
.onPage_side_drawer_Logo {
  width: 40px;
  height: 32px;
  margin-top: 1%;
  margin-left: 8%;
}
.onPage_side_drawer_Heading {
  color: #4791a0;
  font-size: 27px;
  font-weight: 700;
  margin-left: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-bottom: 0.7rem;
}
.sideDrawerBgSelected {
  margin: 0rem 0rem;
  font-size: 19px;
  width: 100%;
  padding: 10px;
  padding-left: 1.2rem;
  border-radius: 3px;
  background-color: rgb(219, 235, 235);
  color: rgb(28, 119, 112);
}
