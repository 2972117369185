.VerifyAudio {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 92%;
  position: absolute;
  background: rgb(217, 244, 238);
  background: linear-gradient(
    180deg,
    rgba(217, 244, 238, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.VerifyAudio_header {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0% 3%;
}

.verifyaudio_bookimage {
  width: 120px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgb(192, 189, 189);
}

.verifyaudio_platformtitle{
  color: grey;
  text-align: center;
}

.verifyaudio_popover_listitem{
  color: black;
  margin: 5px;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
}

.verifyaudio_popover_listitem:hover{
  background-color: #59cbb7;
  color: white;
}

.verifyaudio_headertitle {
  font-weight: bold;
  margin-top: 2.5%;
  display: flex;
}

.verifyaudio_headercaption {
  font-weight: 500;
  color: gray;
  margin-bottom: 10px;
}

.verifyaudio_playerdiv {
  width: 70%;
}

.verifyaudio_buttondiv{
  width: 15%;
}

.verifyaudio_reject_div {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#dropdown-basic-verifyaudio{
  margin-top: 15%;
  margin-right: 6%;
  width: 100%;
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(170, 169, 169, 0.3);
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  background-color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 15px;
  color: #59cbb7;
  border: solid;
  border-width: 1px;
  border-color: #ffffff;
}

#dropdown-basic-verifyaudio:active{
  border: none;
}

.verifyaudio_platformbutton {
  width: 100%;
  margin-top: 15%;
  margin-right: 6%;
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(170, 169, 169, 0.3);
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  background-color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 15px;
  color: #59cbb7;
  border: solid;
  border-width: 1px;
  border-color: #ffffff;
}

.verifyaudio_accept {
  margin-top: 15%;
  margin-right: 6%;
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(170, 169, 169, 0.3);
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  background-color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 21px;
  color: #59cbb7;
  border: solid;
  border-width: 1px;
  border-color: #ffffff;
}

.verifyaudio_accept_disabled {
  margin-top: 15%;
  pointer-events: none;
  box-shadow: 1px 1px 2px rgba(170, 169, 169, 0.3);
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  background-color: lightgrey;
  font-weight: bold;
  font-size: 21px;
  color: #ffffff;
  border: solid;
  border-width: 1px;
  border-color: #ffffff;
}

/* .verifyaudio_accept:hover {
  transition: all 0.2s ease-in-out;
  background: #59cbb7;
  color: #ffffff;
} */

.verifyaudio_reject {
  font-size: 21px;
  margin-top: 15%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #59cbb7;
  cursor: pointer;
}

.verifyaudio_reject:hover {
  color: #369785;
}
/* #ACDEE7 */
.verifyAudio_play {
  background: #59cbb7;
  color: white;
  font-size: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px rgb(192, 189, 189);
  outline: none;
}

.verifyAudio_pause {
  background: #59cbb7;
  color: white;
  font-size: 50%;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 5px 2px rgb(240, 235, 235);
  outline: none;
}

.verifyaudio_maindiv {
  width: 100%;
  padding: 1% 5%;
  overflow-y: scroll;
}

.verifyaudio_tableheader {
  width: 100%;
  display: flex;
}

.verifyaudio_tablecaption {
  width: 20%;
  margin: 0% 1.35%;
  color: grey;
  font-weight: bold;
  text-align: center;
}

.verifyaudio_tablecaption_audio {
  width: 20%;
  margin: 0% 0.9%;
  color: grey;
  font-weight: bold;
  text-align: center;
}

.verifyaudio_tablecaption_number {
  width: 5%;
  margin: 0% 2%;
  color: grey;
  font-weight: bold;
}

.verifyaudio_tablerow {
  padding: 1% 0%;
  display: flex;
  align-items: center;
  width: 100%;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: lightgreen;
  cursor: pointer;
}

.verifyaudio_tablerow:hover {
  transition: all 0.2s ease-in-out;
  background: white;
}

.verifyaudio_tablerow_selected {
  padding: 1% 0%;
  display: flex;
  align-items: center;
  width: 100%;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: lightgreen;
  background: white;
  cursor: pointer;
}

.verifyaudio_tableitem_number {
  width: 5%;
  margin: 0% 2%;
  color: grey;
  font-weight: 500;
}

.verifyaudio_tableitem {
  width: 20%;
  margin: 0% 2%;
  color: grey;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verifybook_tableitemplay {
  width: 30px;
  height: 30px;
  padding-left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-weight: bold;
  font-size: 20px;
  color: #59cbb7;
  transition: all 0.2s ease-in-out;
  border: 2px solid #59cbb7;
  border-radius: 30px;
}

.verifybook_tableitemplay:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.3);
}

.verifybook_tableitempause {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-weight: bold;
  font-size: 20px;
  color: #59cbb7;
  transition: all 0.2s ease-in-out;
  border: 2px solid #59cbb7;
  border-radius: 30px;
}

.verifybook_tableitempause:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.3);
}

.verifyaudio_tableitem_icon {
  width: 20%;
  margin: 0%;
  color: grey;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.verifybook_download {
  width: 30px;
  height: 30px;
  transition: all 0.2s ease-in-out;
}

.verifybook_download:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.3);
}

.verifyaudio_tableitem_number_selected {
  width: 5%;
  margin: 0% 2%;
  color: grey;
  font-weight: 500;
  color: #59cbb7;
}

.verifyaudio_tableitem_selected {
  width: 20%;
  margin: 0% 2%;
  color: grey;
  font-weight: 500;
  color: #59cbb7;
  display: flex;
  justify-content: center;
}

.verifybook_rowplay {
  width: 15px;
  height: 15px;
}
.audioverificationPage_spinner {
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4%;
}

.audioverificationPage_spinner_Null {
  width: 0%
}

.verifyaudio_playerdiv_heading_Main {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
}

.verifyaudio_playerdiv_heading {
  width: 50%;
}
