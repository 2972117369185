.exitfeedback_modal .modal-content{
    display: flex;
    background-color: transparent;
    border: none;
    width: 100%;
}

.disabled_button{
    pointer-events: none;
    opacity: 0.6;
}

.exitfeedback_container{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 2% 2% 5% 2%;
    border-radius: 5px;
}

.popclose_closeImage{
    width: 3%;
    align-self: flex-end;
    cursor: pointer;
}

.popclose_innerdiv{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.popup_logo{
    width: 4%;
}
.popup_heading{
    color: #59cbb7;
    font-weight: 600;
    font-size: 120%;
}
.logoheading_horizontal{
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup_title{
    font-weight: bold;
    font-size: 220%;
    color: #1d515a;
    width: 100%;
    text-align: center;
}
.popup_title2{
    font-size: 110%;
    color: #1d515a;
    width: 90%;
    text-align: center;
}

.popup_textinput{
    border: none;
    width: 50%;
    height: 50px;
    margin-top: 10px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid rgb(216, 211, 211);
}

::placeholder {
    /* font-style: italic; */
    opacity: 0.6;
}
.popup_submit{
    display: flex;
    justify-content: center;
    /* background-color: #59cbb7; */
    color: #59cbb7;
    align-items: center;
    width: 40%;
    height: 50px;
    border-width: 1px;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid  #59cbb7;
}

.popup_horiz_separator{
margin-top: 20px;
}