.projects_emptycontainer{
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(128, 128, 128,0.5);
  }

  .projects_emptycontainer h1{
    font-weight: bold;
    margin-bottom: 2%;
  }

  .projects_emptycontainer h4{
    font-weight: 300;
    display: flex;
  }