.toolbar {
  position: fixed;
  width: 100%;
  background: white;
  height: 60px;
  left: 0%;
  z-index: 999;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
}

.toolbarNone{
  display: none;
}

.spacer {
  flex: 1;
}

.toolbar_userlist_title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.toolbar_logo {
  /* display: flex;
  flex-direction: row; */
  width: 400px;
}

.toolbar_navigation {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.toolbar_navigation_items {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  width: 90%;
}

.toolbar_navigation_items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation_items li {
  padding: 1.2rem;
  color: #59cbb7;
  font-size: 1.2vw;
  font-weight: 500;
  cursor: pointer;
}
.toolbar_navigation_items a:hover {
  color: #19a7b8;
  text-decoration: none;
}

/* .toolbar_navigation_items li:hover,
.toolbar_navigation_items li:active {
  color: #59cbb7;
  text-decoration: underline;
} */

.toolbar_navigation_items a {
  color: #19a7b8;
  font-size: 18px;
  font-weight: 500;
}

/* .toolbar_navigation_items a:hover,
.toolbar_navigation_items a:active {
  color: #59cbb7;
  text-decoration: underline;
} */

@media (min-width: 700px) {
  .toolbar_toggle_button {
    display: none;
  }
}
.HeadingAppName {
  color: #59cbb7;
  font-size: 1.5vw;
  /* padding: 10px; */
  /* padding-top: 15px; */
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.toolbar_popover_listitem {
  display: flex;
  align-items: center;
  margin: 2.5% 0% 5% 0%;
}

.toolbar_popover_listitem_separator {
  width: 100%;
  border: 1px solid rgb(250, 240, 240);
}

.toolbar_avatar_image {
  width: 1.3vw;
  cursor: pointer;
}
.toolbar_popover_avatar_image {
  width: 10%;
  margin-left: 5%;
  cursor: pointer;
  margin-right: 5%;
}


.audifyzLogo {
  width: 2vw;
  margin-top: 0.5%;
  margin-right: 2%;
  margin-left: 3%;
  object-fit: contain;
}

.toolbarOnselectedpage {
  border-bottom: 4px solid #59cbb7;
  margin-top: 3px;
}

@media (min-width: 700px) {
  .toolbar_toggle_button {
    display: none;
  }
}

@media (max-width: 700px) {
  .toolbar_navigation_items {
    display: none;
  }

  .HeadingAppName {
    color: #59cbb7;
    font-size: 3vh;
    /* padding: 10px; */
    /* padding-top: 15px; */
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  .audifyzLogo {
    width: 3vh;
    margin-top: 0.5%;
    margin-right: 2%;
    margin-left: 3%;
    object-fit: contain;
  }
}
.custom-schedule-button{
  color: #3e897c !important;
  font-weight: bold !important;
}